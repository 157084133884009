import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Category, IBaseCategory } from './common/categories.interface';


@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private readonly API = `${env.API_URL}/categories`;


  constructor(private readonly http: HttpClient) { }

  findAll() {
    return this.http.get<Category[]>(this.API);
  }

  create(data: FormData) {
    return this.http.post<Category>(this.API, data);
  }

  findById(id: string) {
    return this.http.get<Category>(`${this.API}/${id}`);
  }

  update(id: string, formaData: FormData) {
    return this.http.put<Category>(`${this.API}/${id}`, formaData);
  }

  deleteById() {

  }
}
