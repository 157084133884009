<form [formGroup]="form" class="shipping-form">
    <div class="form-group">
      <label for="company_name">Company Name</label>
      <input 
        id="company_name" 
        formControlName="company_name" 
        type="text" 
        class="form-control" 
        placeholder="Enter the company name" 
      />
      <div *ngIf="form.controls['company_name'].invalid && form.controls['company_name'].touched" class="error-message">
        Company Name is required.
      </div>
    </div>
  
    <div class="form-group">
      <label for="tracking_id">Tracking ID</label>
      <input 
        id="tracking_id" 
        formControlName="tracking_id" 
        type="text" 
        class="form-control" 
        placeholder="Enter the tracking ID" 
      />
      <div *ngIf="form.controls['tracking_id'].invalid && form.controls['tracking_id'].touched" class="error-message">
        Tracking ID is required.
      </div>
    </div>
  
    <div class="form-group">
      <label for="company_url">Company URL</label>
      <input 
        id="company_url" 
        formControlName="company_url" 
        type="text" 
        class="form-control" 
        placeholder="Enter the company URL (optional)" 
      />
    </div>
  </form>
  