import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsComponent } from './products/pages/products/products.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { ProductComponent } from './products/pages/product/product.component';
import { CategoriesListComponent } from './categories/categories-list/categories-list.component';
import { CategoriesFormComponent } from './categories/categories-form/categories-form.component';
import { HomeComponent } from './home/home.component';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { AuthGuard } from './shared/components/auth/auth.guard';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { ShipmentsComponent } from './shipments/shipments/shipments.component';
import { ShipmentComponent } from './shipments/shipment/shipment.component';
import { ForbiddenComponent } from './shared/components/forbidden/forbidden.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MenuComponent,
    //canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: HomeComponent,
      },
      {
        path: 'categories',
        component: CategoriesListComponent,
      },
      {
        path: 'categories/:id/edit',
        component: CategoriesFormComponent,
      },
      {
        path: 'categories/create',
        component: CategoriesFormComponent,
      },
      {
        path: 'products',
        component: ProductsComponent,
      },
      { 
        path: 'products/create', 
        component: ProductComponent,
      },
      { 
        path: 'products/:id/edit', 
        component: ProductComponent,
      },
      {
        path: 'orders',
        component: OrdersListComponent,
      },
      {
        path: 'orders/:id/details',
        component: OrderDetailsComponent
      },
      {
        path: 'shipments',
        component: ShipmentsComponent,
      },
      {
        path: 'shipments/create',
        component: ShipmentComponent
      },
      { 
        path: 'shipments/:id/edit', 
        component: ShipmentComponent,
      },
      {
        path: 'forbidden',
        component: ForbiddenComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
