<ng-container *ngIf="!loading; else skeleton">
  <div class="main-container">
    <div *ngIf="!isMobile()">
      <nz-page-header
        [nzTitle]="title"
        nzBackIcon
        (nzBack)="onBackConfirm()">
        <ng-template #title>
          {{ isOnCreationMode ? 'Kreye pwodui' : 'Modifye pwodui' }}
          <span *ngIf="form && form.dirty">*</span>
        </ng-template>
        <nz-page-header-extra>
          <ng-container>
            <button nz-button nzType="primary" (click)="onSave()" [nzLoading]="saving">
              Anrejistre
            </button>
          </ng-container>
        </nz-page-header-extra>
      </nz-page-header>
    </div>
    <div *ngIf="isMobile()" class="mobile-page-header">
      <nz-page-header [nzTitle]="title" nzBackIcon (nzBack)="onBackConfirm()">
        <ng-template #title>
          {{ isOnCreationMode ? 'Kreye pwodui' : 'Modifye pwodui' }}
          <span *ngIf="form && form.dirty">*</span>
        </ng-template>
        <nz-page-header-extra>
          <ng-container>
            <button nz-button nzType="primary" (click)="onSave()" [nzLoading]="saving">
              Anrejistre
            </button>
          </ng-container>
        </nz-page-header-extra>
      </nz-page-header>
    </div>

    <div *ngIf="!isMobile()" style="margin-top: 16px;">
      <nz-steps nzType="navigation" nzSize="small" [nzCurrent]="selectedStep" (nzIndexChange)="onStepChange($event)">
        <nz-step *ngFor="let step of steps; let i = index" 
                [nzTitle]="step.title" 
                [nzDisabled]="isOnCreationMode && step.title === 'Imaj'">
        </nz-step>
      </nz-steps>
    </div>

    <div *ngIf="isMobile()" >
      <h4>{{ steps[selectedStep].title }}</h4>
      <ng-container [ngSwitch]="selectedStep">
        <ng-container *ngSwitchCase="0">
        </ng-container>
        <ng-container *ngSwitchCase="1">
        </ng-container>
        <ng-container *ngSwitchCase="2">
        </ng-container>
        <ng-container *ngSwitchCase="3">
        </ng-container>
      </ng-container>
    </div>
    <div nz-col [nzSpan]="24" style="margin-top: 24px;">
      <ng-container [ngSwitch]="selectedStep">
        <form nz-form nzLayout="vertical" [formGroup]="form">

          <ng-container *ngSwitchCase="0">
            <div nz-row>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Non</nz-form-label>
                  <nz-form-control nzErrorTip="Non pwodui an obligatwa">
                    <input nz-input formControlName="name"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Kategori</nz-form-label>
                  <nz-form-control nzErrorTip="Kategori pwodui an obligatwa">
                    <nz-select 
                      formControlName="categories_id" 
                      nzMode="multiple" 
                      nzShowSearch 
                      nzAllowClear 
                      nzPlaceHolder="Chwazi kategori pwodwi a">
                      <nz-option 
                        *ngFor="let category of categories" 
                        [nzLabel]="category.name" 
                        [nzValue]="category.id">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>    

            <div nz-row>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Deskripsyon</nz-form-label>
                  <nz-form-control nzErrorTip="Deskripsyon pwodui an obligatwa">
                    <textarea nz-input placeholder="Dekri pwodui a" formControlName="description"></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired>
                    Pri ({{ getCurrencySymbol(product.currency) }})
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Pri pwodui an obligatwa">
                    <input nz-input type="number" formControlName="price" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>                        
            <div nz-row>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Kantite disponib</nz-form-label>
                  <nz-form-control nzErrorTip="Kantite pwodui an obligatwa">
                    <input nz-input type="number" formControlName="available_amount" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label> Valè Rabè</nz-form-label>
                  <nz-form-control>
                    <input nz-input type="number" formControlName="discount_amount" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="1" style="margin-top: 12px;">
            <ng-container *ngIf="!images || images.length < 4">
              <div nz-col [nzSpan]="24">
                <nz-upload
                  nzType="drag"
                  [nzLimit]="4" 
                  [nzFileList]="fileList" 
                  [nzMultiple]="true"
                  [nzCustomRequest]="onCustomRequest"
                  nzAccept=".jpg,.jpeg,.png,.gif">
                  <p class="ant-upload-drag-icon">
                    <span nz-icon nzType="inbox"></span>
                  </p>
                  <p class="ant-upload-text">Klike oswa drage fichye a pou w telechaje</p>
                </nz-upload>
              </div>
            </ng-container>            
            <ng-container *ngIf="images;">
              <div nz-row [nzGutter]="8">
                <div nz-col [nzXs]="8" [nzSm]="4" [nzMd]="6" *ngFor="let image of images" style="margin-top: 8px;">
                  <div *ngIf="!image.removed">
                    <nz-card nzHoverable [nzCover]="imageCoverTemplate"></nz-card>
                    <ng-template #imageCoverTemplate>
                      <ng-container>
                        <img height="200px" [src]="image.url" style="display: none;" #img />
                        <img height="200px" nz-image [nzSrc]="img.src" style="cursor: pointer;"/>
                      </ng-container>
                      <span 
                        style="margin-top: 12px; color: red; font-size: 18px;" 
                        nz-icon 
                        nzType="delete" 
                        (click)="confirmationMessage(image.id)" 
                        nzTheme="outline">
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="2" style="margin-top: 12px;">
            <ng-container formGroupName="shipping_specification">
              <div nz-row [nzGutter]="16">
                <div nz-col [nzSpan]="12">
                  <nz-form-item>
                    <nz-form-label>Longè</nz-form-label>
                    <nz-form-control>
                      <input nz-input type="number" formControlName="length" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="12">
                  <nz-form-item>
                    <nz-form-label>Lajè</nz-form-label>
                    <nz-form-control>
                      <input nz-input type="number" formControlName="width" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row [nzGutter]="16">
                <div nz-col [nzSpan]="12">
                  <nz-form-item>
                    <nz-form-label>Wotè</nz-form-label>
                    <nz-form-control>
                      <input nz-input type="number" formControlName="height" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="12">
                  <nz-form-item>
                    <nz-form-label>Pwa</nz-form-label>
                    <nz-form-control>
                      <input nz-input type="number" formControlName="weight" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </ng-container>
          
            <div nz-row>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Livrezon</nz-form-label>
                  <nz-form-control nzErrorTip="Livrezon an obligatwa">
                    <nz-select 
                      formControlName="shipments_id" 
                      nzMode="multiple" 
                      nzShowSearch 
                      nzAllowClear 
                      nzPlaceHolder="Chwazi opsyon livrezon pwodui a">
                      <nz-option 
                        *ngFor="let shipment of shipments" 
                        [nzLabel]="shipment.description" 
                        [nzValue]="shipment.id">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="3">
            <div style="margin-top: 30px;">
              <nz-page-header>
                <nz-page-header-extra>
                  <ng-container>
                    <button nz-button nzType="primary" (click)="addVariant()">
                      Adisyone
                    </button>
                  </ng-container>
                </nz-page-header-extra>
              </nz-page-header>
              <ng-container *ngIf="tableVariantes && tableVariantes.length > 0">
                <nz-collapse>
                  @for (variant of tableVariantes; track variant) {
                    <nz-collapse-panel [nzHeader]="variantTitle"  [nzExtra]="button">
                      <ng-template #variantTitle>
                        {{"kantite: "}} {{variant.quantity}}
                      </ng-template>
                      <ng-template #button>
                        <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary" [nzPlacement]="'bottomCenter'">
                          {{ 'Aksyon'}}
                        </button>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                          <ul nz-menu>
                            <li nz-menu-item>
                              <a nz-button nzType="link" (click)="onAddAttribute(variant)">
                                {{ 'kreye atribi' }}
                              </a>
                            </li>
                            <li nz-menu-item>
                              <a nz-button nzType="link" nzDanger (click)="onRemoveVariant(variant.id)">
                                {{ 'Excluir'}}
                              </a>
                            </li>
                          </ul>
                        </nz-dropdown-menu>
                      </ng-template>

                      <nz-table [nzData]="variant.attributes">
                        <thead>
                          <tr>
                            <th>Atribi</th>
                            <th nzAlign="center">Koulè | Gwosè</th>
                            <th nzAlign="center">Kantite</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let atribute of variant.attributes">
                            <td *ngIf="atribute?.key">{{atribute.key}}</td>
                            <td *ngIf="atribute?.value" nzAlign="center">
                              <ng-container *ngIf="atribute.key === 'color'">
                                {{ t('products.product.colors.'+ atribute.value) }}
                              </ng-container>
                              <ng-container *ngIf="atribute.key !== 'color'">
                                {{ atribute.value }}
                              </ng-container>
                            </td>
                            <td width="100px" nzAlign="center">
                              <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary" [nzPlacement]="'bottomCenter'">
                                {{ 'Aksyon'}}
                              </button>
                              <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item>
                                    <a nz-button nzType="link" nzDanger (click)="onRemove(atribute.id, variant)">
                                      {{ 'Excluir'}}
                                    </a>
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                            </td>
                          </tr>          
                        </tbody>
                      </nz-table>
                    </nz-collapse-panel>
                  }
                </nz-collapse>
              </ng-container>
            </div>
          </ng-container>

          <!-- <ng-container *ngSwitchCase="2" style="margin-top: 12px;">
            <div nz-row>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Rabè</nz-form-label>
                  <nz-form-control>
                    <input nz-input type="number" formControlName="amount" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
                <div nz-col [nzSpan]="24">
                  <nz-form-item>
                    <nz-form-label>Dat</nz-form-label>
                    <nz-form-control>
                      <nz-range-picker
                        formControlName="dateRange"
                        nzFormat="dd/MM/yyyy">
                      </nz-range-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
            </div> 
          </ng-container> -->

        </form>
      </ng-container>
    </div>      
    <div class="footer" style="display: flex; justify-content: flex-end; align-items: center; margin-top: 24px;">
      <button nz-button (click)="previousStep()" *ngIf="selectedStep > 0" style="margin-right: 10px;">
        Back
      </button>
      <button nz-button nzType="primary" (click)="nextStep()" [disabled]="selectedStep === steps.length - 1">
        Next
      </button>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
