<div class="shipping-activity">
    <header>
        <strong>{{ t('orders.shipping_activities')}}</strong>
    </header>
    <div class="shipping-timeline">
        <ul class="timeline">
          <li *ngFor="let status of allStatuses" class="timeline-item">
            <div 
              [ngClass]="{
                'status-dot': true,
                'cancelled': status === 'CANCELLED'
              }"></div>
            <span 
              [ngClass]="{
                'status-label': true,
                'active': isStatusActive(status)
              }">
              {{ getStatusLabel(status) }}
            </span>
          </li>
        </ul>
        
    </div>
</div>