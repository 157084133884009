<ng-container>
  <nz-page-header nzTitle="Pwodui">
    <nz-page-header-extra>
      <input 
        *ngIf="isSearchVisible"
        nz-input
        placeholder="Chèche yon pwodui..."
        style="margin-right: 16px; width: 200px;">
      <span
        style="margin-right: 24px; cursor: pointer;"
        nz-icon
        nzType="search"
        nzTheme="outline"
        (click)="toggleSearch()">
      </span>
      <button nz-button nzType="primary" (click)="onNew()">
        {{ 'Nouvo' }}
      </button>
    </nz-page-header-extra>    
  </nz-page-header>
  <div nz-row style="margin-top: 8px;" *ngIf="!loading; else skeleton">
    <div nz-col [nzSpan]="24" *ngIf="isDesktopView; else cardView">
        <nz-table #table [nzData]="products" [nzTotal]="totalItems"
            [nzLoading]="loading" [nzSize]="'small'" [nzBordered]="true" [nzFrontPagination]="false"
            [nzPageSize]="pageSize" [nzPageIndex]="currentPage"
            (nzPageIndexChange)="onPageChange($event)"
            (nzPageSizeChange)="onPageSizeChange($event)"
            (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger>
            <thead>
              <tr>
                <th nzColumnKey="id" [nzSortFn]="true" nzAlign="center">Id</th>
                <th nzColumnKey="name" [nzSortFn]="true" nzAlign="center">Non</th>
                <th nzColumnKey="price" [nzSortFn]="true" nzAlign="center">Pri</th>
                <th nzColumnKey="available_amount" [nzSortFn]="true" nzAlign="center">Kantite disponib</th>
                <th nzColumnKey="is_active" [nzSortFn]="true" nzAlign="center">Status</th>
                <th width="100px" nzAlign="center">Aksyon</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of products; index as i" style="cursor: pointer;">
                <td (click)="onEdit(product.id)" nzAlign="center">{{ (currentPage - 1) * pageSize + i + 1 }}</td>
                <td (click)="onEdit(product.id)" nzAlign="center">{{ product.name }}</td>
                <td (click)="onEdit(product.id)" nzAlign="center">{{ product.price | currency: product.currency}}</td>
                <td (click)="onEdit(product.id)" nzAlign="center">{{ product.available_amount }}</td>
                <td (click)="onEdit(product.id)" nzAlign="center">{{ translateStatus(product.status) }}</td>
                <td nzAlign="center">
                  <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary" [nzPlacement]="'bottomCenter'">
                    {{ 'Aksyon'}}
                  </button>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item>
                        <a nz-button nzType="link" (click)="onEdit(product.id)">
                          {{ 'Modifye' }}
                        </a>
                      </li>
                      <li nz-menu-item *ngIf="!['INACTIVE', 'PUBLISHED'].includes(product.status)">
                        <a nz-button nzType="link" class="publish-action" (click)="onPublish(product.id)">
                          {{ 'Pibliye' }}
                        </a>
                      </li>
                      <li nz-menu-item *ngIf="!['INACTIVE', 'UNPUBLISHED'].includes(product.status)">
                        <a nz-button nzType="link" class="unpublish-action" (click)="onUnPublish(product.id)">
                          {{ 'Anile Piblikasyon' }}
                        </a>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
              </tr>
            </tbody>
        </nz-table>
    </div>
    <!-- Card View -->
    <ng-template #cardView >
      <div *ngIf="!products.length" nz-col [nzSpan]="24">
        <nz-empty nzNotFoundContent="Pa gen pwodui ki disponib"></nz-empty>
      </div>
      <div nz-row nzGutter="16" style="width: 100%;">
        <div nz-col [nzSpan]="24" *ngFor="let product of products; index as i">
          <nz-card nzBordered style="margin-bottom: 16px;" >
            <div nz-col [nzSpan]="24">
              <div nz-row >
                <div nz-col nzSpan="12" style="padding-right: 2px;">
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Id</Strong></p>
                    <span>{{product.id}}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Non</Strong></p>
                    <span>{{product.name}}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Price</Strong></p>
                    <span>{{product.price | currency: product.currency}}</span>
                  </div>
                </div>
                <div nz-col nzSpan="12">
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Kantite disponib</Strong></p>
                    <span>{{product.available_amount}}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Status</Strong></p>
                    <span>{{ translateStatus(product.status) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <nz-divider></nz-divider>
            <div nz-row>
              <button nz-col nzSpan="12" nzOffset="6" nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary" [nzPlacement]="'bottomCenter'">
                {{ 'Aksyon'}}
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>
                    <a nz-button nzType="link" (click)="onEdit(product.id)">
                      {{ 'Modifye' }}
                    </a>
                  </li>
                  <li nz-menu-item *ngIf="!['INACTIVE', 'PUBLISHED'].includes(product.status)">
                    <a nz-button nzType="link" class="publish-action" (click)="onPublish(product.id)">
                      {{ 'Pibliye' }}
                    </a>
                  </li>
                  <li nz-menu-item *ngIf="!['INACTIVE', 'UNPUBLISHED'].includes(product.status)">
                    <a nz-button nzType="link" class="unpublish-action" (click)="onUnPublish(product.id)">
                      {{ 'Anile Piblikasyon' }}
                    </a>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </nz-card>
        </div>
      </div>
    </ng-template>
  </div> 
</ng-container>


<ng-template #skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>