import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, of } from "rxjs";
import { CookieService } from "./cookie.service";

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private translations: { [key: string]: any } = {};
  private translationsSubject: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject(this.translations);

  constructor(
    private http: HttpClient,
    private readonly cookieService: CookieService

  ) {
    const lang = cookieService.get('NEXT_I18LANG') || 'fr'
    this.loadTranslations(lang); 
  }

  loadTranslations(language: string): void {
    this.http.get<{ [key: string]: any }>(`assets/i18n/${language}.json`).pipe(
      catchError((error) => {
        console.error(`Error loading translations for ${language}:`, error);
        return of({});
      })
    ).subscribe((translations) => {
      this.translations = translations;
      this.translationsSubject.next(this.translations);
    });
  }

  use(language: string): void {
    this.loadTranslations(language);
  }

  translate(key: string): string {
    const keys = key.split('.');
    let value: any = this.translations;

    for (const k of keys) {
      if (value[k]) {
        value = value[k];
      } else {
        return key;
      }
    }
    return value || key;
  }

  get translations$() {
    return this.translationsSubject.asObservable();
  }
}
