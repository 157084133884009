import { NzI18nInterface } from 'ng-zorro-antd/i18n';

export class CustomLanguage {
  static customLanguage: NzI18nInterface = {
    locale: 'custom',
    Pagination: {
      items_per_page: 'kantite/paj',
      jump_to: 'Ale nan',
      jump_to_confirm: 'Konfime',
      page: 'Paj',
      prev_page: 'Paj anvan',
      next_page: 'Paj pwochen',
      prev_5: '5 Paj anvan',
      next_5: '5 Paj pwochen',
      prev_3: '3 Paj anvan',
      next_3: '3 Paj pwochen'
    },
    Table: {
      filterTitle: 'Meni Filtre',
      filterConfirm: 'OK',
      filterReset: 'Reyajiste',
      selectAll: 'Chwazi tout paj',
      selectInvert: 'Envèse paj',
      sortTitle: 'Triye',
      expand: 'Elaji ranje',
      collapse: 'Kontrakte ranje',
      triggerDesc: 'Klike pou triye desann',
      triggerAsc: 'Klike pou triye monte',
      cancelSort: 'Klike pou anile triye'
    },
    Empty: {
      description: 'Pa gen done'
    },
    DatePicker: {
      lang: {
        placeholder: 'Chwazi dat',
        rangePlaceholder: ['De', 'Jiska'],
        today: 'Jodi a',
        now: 'Kounye a',
        backToToday: 'Retounen nan jodi a',
        ok: 'Ok',
        clear: 'Efase',
        month: 'Mwa',
        year: 'Ane',
        timeSelect: 'Chwazi lè',
        dateSelect: 'Chwazi dat',
        monthSelect: 'Chwazi yon mwa',
        yearSelect: 'Chwazi yon ane',
        decadeSelect: 'Chwazi yon deseni',
        yearFormat: 'YYYY',
        dateFormat: 'M/D/YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'M/D/YYYY HH:mm:ss',
        monthBeforeYear: true,
        previousMonth: 'Mwa anvan (PageUp)',
        nextMonth: 'Mwa pwochen (PageDown)',
        previousYear: 'Ane pase (Control + left)',
        nextYear: 'Ane pwochen (Control + right)',
        previousDecade: 'Deseni pase',
        nextDecade: 'Deseni pwochen',
        previousCentury: 'Syèk pase',
        nextCentury: 'Syèk pwochen'
      },
      timePickerLocale: {
        placeholder: 'Chwazi lè',
      }
    },
    TimePicker: {
      placeholder: 'Chwazi lè',
      rangePlaceholder: ['Kòmanse lè', 'Fini lè']
    },
    Calendar: {
      lang: {
        placeholder: 'Chwazi dat',
        rangePlaceholder: ['Kòmanse dat', 'Fini dat'],
        today: 'Jodi a',
        now: 'Kounye a',
        backToToday: 'Retounen nan jodi a',
        ok: 'Ok',
        clear: 'Efase',
        month: 'Mwa',
        year: 'Ane',
        timeSelect: 'Chwazi lè',
        dateSelect: 'Chwazi dat',
        monthSelect: 'Chwazi yon mwa',
        yearSelect: 'Chwazi yon ane',
        decadeSelect: 'Chwazi yon deseni',
        yearFormat: 'YYYY',
        dateFormat: 'M/D/YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'M/D/YYYY HH:mm:ss',
        monthBeforeYear: true,
        previousMonth: 'Mwa anvan (PageUp)',
        nextMonth: 'Mwa pwochen (PageDown)',
        previousYear: 'Ane pase (Control + left)',
        nextYear: 'Ane pwochen (Control + right)',
        previousDecade: 'Deseni pase',
        nextDecade: 'Deseni pwochen',
        previousCentury: 'Syèk pase',
        nextCentury: 'Syèk pwochen'
      },
      timePickerLocale: {
        placeholder: 'Chwazi lè',
      }
    },
    global: {
      placeholder: 'Tanpri chwazi'
    },
    Modal: {
      okText: 'OK',
      cancelText: 'Anile',
      justOkText: 'OK'
    },
    Popconfirm: {
      okText: 'OK',
      cancelText: 'Anile'
    },
    Transfer: {
      titles: ['', ''],
      searchPlaceholder: 'Rechèch isit la',
      itemUnit: 'atik',
      itemsUnit: 'atik'
    },
    Upload: {
      uploading: 'Telechaje...',
      removeFile: 'Retire fichye',
      uploadError: 'Erè nan telechajman',
      previewFile: 'Gade fichye',
      downloadFile: 'Telechaje fichye'
    },
    Text: {
      edit: 'Modifye',
      copy: 'Kopi',
      copied: 'Kopye',
      expand: 'Elaji'
    }
  };
}
