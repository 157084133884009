import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Shipment, ShipmentPayload } from '../shared/model/shipment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {


  private readonly API = `${env.API_URL}/management`;


  constructor(
    private readonly http: HttpClient
  ) {}

  findAll() {
    return this.http.get<Shipment[]>(`${this.API}/shipments`);
  }

  create(shipment: ShipmentPayload): Observable<Shipment> {
    const url = `${this.API}/shipments`;
    return this.http.post<Shipment>(url, shipment);
  }

  update(shipmentId: string, shipment: ShipmentPayload): Observable<Shipment> {
    const url = `${this.API}/shipments/${shipmentId}`;
    return this.http.put<Shipment>(url, shipment);
  }
}
