
export enum UserRole {
    ADMIN = 'ADMINISTRATOR',
    SELLER = 'SELLER',
    BUYER = 'BUYER',
}

export interface SellerInfos {

}

interface Country {
    code: string;
    name: string;
  }
  
  interface Address {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    zipCode: string;
    country: Country;
    state_or_department: string;
  }
  
  interface Currency {
    currencyCode: string;
    numericCode: number;
    numericCodeAsString: string;
    displayName: string;
    symbol: string;
    defaultFractionDigits: number;
  }
  
  interface PaymentDetails {
    payment_strategy: string;
    payment_account_id: string;
    payment_details_submitted: boolean;
    payment_payouts_enabled: boolean;
    payment_charges_enabled: boolean;
  }
  
  interface BusinessAccountDetails {
    id: string;
    deletedAt: string;
    currency: Currency;
    address: Address;
    description: string;
    rating: number;
    business_name: string;
    account_status: string;
    payment_details: PaymentDetails;
    business_categories: string[];
    is_onboarding_completed: boolean;
    is_able_to_sell: boolean;
  }
  
  interface ShippingContact {
    name: string;
    phoneNumber: string;
  }
  
  export interface AuthUserInterface {
    id: string;
    name: string;
    email: string;
    phone_number?: string;
    roles: UserRole[];
    address?: Address;
    business_account: BusinessAccountDetails
}
  