<nz-content style="margin-top: 32px" *ngIf="userLoaded; else skeleton">   
  <div nz-row class="welcome-section" style="margin-top: -24px;">
    <div nz-col [nzSpan]="12" class="welcome-text">
      <h2 *ngIf="user?.name" class="welcome-title">
        {{ t('common.welcome') }}, {{ user?.name }}!
      </h2>
      <p class="welcome-subtitle">
        {{ t('common.welcome_message') }}
      </p>
      <button class="create-product-btn" (click)="onNew()">{{ t('common.create_product') }}</button>
    </div>
    <div nz-col [nzSpan]="12" class="welcome-image-container"></div>    
  </div>  
  
  <div *ngIf="!isSellerPaymentOnboardingCompleted && !isAdmin" class="center-align" style="margin-bottom: 32px;">
    <button nz-button nzType="primary" [nzLoading]="saving" (click)="setupPayout()" class="payout-btn">
      <i nz-icon nzType="dollar-circle" class="icon"></i> {{ t('common.payment_configuration_message')}} <i nz-icon nzType="link"></i>
    </button>
  </div>

  <div *ngIf="isSellerPaymentOnboardingCompleted" class="dashboard-overview">
    <nz-row [nzGutter]="16" style="margin-bottom: 32px;">
      <nz-col [nzSpan]="24" [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6">
        <nz-card class="statistic-card" (click)="goProducts()" style="cursor: pointer;">
          <div class="card-content">
            <nz-statistic [nzValue]="totalProducts" nzTitle="Total Products"
                          [nzValueStyle]="{ fontSize: '36px', fontWeight: '600', color: '#4CAF50' }">
            </nz-statistic>
            <i nz-icon nzType="appstore" class="stat-icon"></i>
          </div>
        </nz-card>
      </nz-col>

      <!-- Total Orders Card -->
      <nz-col [nzSpan]="24" [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6">
        <nz-card class="statistic-card" (click)="goOrders()" style="cursor: pointer;">
          <div class="card-content">
            <nz-statistic [nzValue]="totalOrders" nzTitle="Total Orders"
                          [nzValueStyle]="{ fontSize: '36px', fontWeight: '600', color: '#FF9800' }">
            </nz-statistic>
            <i nz-icon nzType="shopping-cart" class="stat-icon"></i>
          </div>
        </nz-card>
      </nz-col>

      <!-- Pending Orders Card -->
      <nz-col [nzSpan]="24" [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6">
        <nz-card class="statistic-card">
          <div class="card-content">
            <nz-statistic [nzValue]="pendingOrders" nzTitle="Pending Orders"
                          [nzValueStyle]="{ fontSize: '36px', fontWeight: '600', color: '#FF5722' }">
            </nz-statistic>
            <i nz-icon nzType="clock-circle" class="stat-icon"></i>
          </div>
        </nz-card>
      </nz-col>

      <!-- Delivered Orders Card -->
      <nz-col [nzSpan]="24" [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6">
        <nz-card class="statistic-card">
          <div class="card-content">
            <nz-statistic [nzValue]="deliveredOrders" nzTitle="Delivered Orders"
                          [nzValueStyle]="{ fontSize: '36px', fontWeight: '600', color: '#4CAF50' }">
            </nz-statistic>
            <i nz-icon nzType="check-circle" class="stat-icon"></i>
          </div>
        </nz-card>
      </nz-col>
    </nz-row>
  </div>
  <div style="display: flex; justify-content: space-around; flex-wrap: wrap;">
    <div style="width: 45%; min-width: 300px;">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [labels]="chartOptions.labels"
        [responsive]="chartOptions.responsive"
        [colors]="chartOptions.colors"
        [legend]="chartOptions.legend">
      </apx-chart>
    </div>
  
    <div style="width: 45%; min-width: 300px;">
      <apx-chart
        [series]="chartOptionsStatistic.series"
        [chart]="chartOptionsStatistic.chart"
        [xaxis]="chartOptionsStatistic.xaxis"
        [dataLabels]="chartOptionsStatistic.dataLabels"
        [title]="chartOptionsStatistic.title"
        [colors]="chartOptionsStatistic.colors">
      </apx-chart>
    </div>
  </div>

  <div *ngIf="isDesktopView; else cardView" style="margin-top: 12x;">
    <nz-table
      #table
      [nzData]="orders"
      [nzLoading]="loading"
    >
      <thead>
        <tr>
          <th nzAlign="center">
            {{ t("orders.order_id") }}
          </th>
          <th nzAlign="center">
            {{ t("orders.created_at") }}
          </th>
          <th nzAlign="center">
            {{ t("orders.customer") }}
          </th>
          <th nzAlign="center">
            {{ t("orders.product") }}
          </th>
          <th nzAlign="center">
            {{ t("orders.quantity") }}
          </th>
          <th nzAlign="center">
            {{ t("orders.unit_price") }}
          </th>
          <th nzAlign="center">
            {{ t("orders.subtotal") }}
          </th>
          <th nzAlign="center">
            {{ t("orders.status") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orders" style="cursor: pointer">
          <td nzAlign="center">
            {{ order.public_id }}
          </td>
          <td nzAlign="center">
            {{ order.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}
          </td>
          <td nzAlign="center">
            {{ order.customer.name }}
          </td>
          <td nzAlign="center">
            {{ order.product.name }}
          </td>
          <td nzAlign="center">
            {{ order.quantity }}
          </td>
          <td nzAlign="center">
            {{ order.unit_price | currency : order.currency }}
          </td>
          <td nzAlign="center">
            {{ order.sub_total | currency : order.currency }}
          </td>
          <td nzAlign="center">
            {{ translateStatus(order.status) }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>

  <!-- Card View -->
  <ng-template #cardView >
    <div nz-row nzGutter="16" style="width: 100%;">
      <div nz-col [nzSpan]="24" *ngFor="let order of orders">
        <nz-card [nzTitle]="order.public_id" nzHoverable nzBordered style="margin-bottom: 16px;" >
          <div nz-col [nzSpan]="24">
            <div nz-row >
              <div nz-col nzSpan="12" style="padding-right: 2px;">
                <div style="margin-bottom: 16px;">
                  <p style="font-weight: 400; margin: 0;">
                    <i nz-icon nzType="calendar" nzTheme="outline"></i>
                    <strong>{{ t("orders.created_at") }}</strong>
                  </p>
                  <span>{{ order.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
                </div>
                <div style="margin-bottom: 16px;">
                  <p style="font-weight: 400; margin: 0;">
                    <i nz-icon nzType="user" nzTheme="outline"></i>
                    <Strong>{{ t("orders.customer") }}</Strong>
                  </p>
                  <span>{{ order.customer.name }}</span>
                </div>
                <div style="margin-bottom: 16px;">
                  <p style="font-weight: 400; margin: 0;">
                    <i nz-icon nzType="shopping" nzTheme="outline"></i>
                    <strong>{{ t("orders.product") }}</strong>
                  </p>
                  <span>{{ order.product.name }}</span>
                </div>
                <div style="margin-bottom: 16px;">
                  <p style="font-weight: 400; margin: 0;">
                    <i nz-icon nzType="number" nzTheme="outline"></i>
                    <strong>{{ t("orders.quantity") }}</strong>
                  </p>
                  <span>{{ order.quantity }}</span>
                </div>
              </div>
              <div nz-col nzSpan="12">
                <div style="margin-bottom: 16px;">
                  <p style="font-weight: 400; margin: 0;">
                    <i nz-icon nzType="dollar" nzTheme="outline"></i>
                    <Strong>{{ t("orders.unit_price") }}</Strong>
                  </p>
                  <span>{{ order.unit_price | currency : order.currency }}</span>
                </div>
                <div style="margin-bottom: 16px;">
                  <p style="font-weight: 400; margin: 0;">
                    <i nz-icon nzType="wallet" nzTheme="outline"></i>
                    <Strong>{{ t("orders.subtotal") }}</Strong>
                  </p>
                  <span>{{ order.sub_total | currency : order.currency }}</span>
                </div>
                <div style="margin-bottom: 16px;">
                  <p style="font-weight: 400; margin: 0;">
                    <i nz-icon nzType="tag" nzTheme="outline"></i>
                    <Strong>{{ t("orders.status") }}:</Strong>
                  </p>
                  <span>{{ translateStatus(order.status) }}</span>
                </div>
              </div>
            </div>
          </div>
        </nz-card>
      </div>
    </div>
  </ng-template>
</nz-content>

<!-- Skeleton Loader -->
<ng-template #skeleton>
  <nz-skeleton [nzActive]="true" style="margin-bottom: 20px;"></nz-skeleton>
  <nz-skeleton [nzActive]="true" style="margin-bottom: 20px;"></nz-skeleton>
  <nz-skeleton [nzActive]="true" style="margin-bottom: 20px;"></nz-skeleton>
</ng-template>
