import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs';
import { environment as env } from '../../../../environments/environment';
import { AuthUserInterface, UserRole } from '../../model/auth-user.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomAuthService {
  private accessToken: string = '';

  private authUserSubject = new BehaviorSubject<any>(null);

  authUser$= this.authUserSubject.asObservable();

  constructor(
    public readonly auth: AuthService,
    private readonly http: HttpClient
  ) {
    // this.validateToken();
  }

  async validateToken(): Promise<boolean> {
    const loggedIn = await firstValueFrom(this.auth.isAuthenticated$);

    if (loggedIn) {
      try {
        this.accessToken = await firstValueFrom(
          this.auth.getAccessTokenSilently()
        );
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  isAuthenticated(): Promise<boolean> {
    return firstValueFrom(this.auth.isAuthenticated$);
  }

  login() {
    this.auth.loginWithRedirect({
      prompt: 'none',
    } as any);
  }

  getToken(): string {
    return this.accessToken;
  }

  userAuthorized() {
    const user = this.getAuthUser();
    return (
      user?.roles.includes(UserRole.ADMIN) ||
      (user?.roles.includes(UserRole.SELLER) && user?.business_account)
    );
  }

  getUserFromApi(): Observable<AuthUserInterface> {
    return this.http.get<AuthUserInterface>(`${env.API_URL}/users/me`).pipe(
      tap((authUserData) => {
        this.authUserSubject.next(authUserData);
      })
    );
  }

  getAuthUser() {
    return this.authUserSubject.value;
  }

  logout() {
    this.authUserSubject.next(null);
    this.auth.logout({
      logoutParams: {
        returnTo: env.STORE_URL,
      },
    });
  }
}
