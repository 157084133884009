import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/components/ui/base-component';
import { CategoriesService } from '../categories.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Category } from '../common/categories.interface';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { CommonModule } from '@angular/common';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FileUploaderComponent } from '../../shared/components/file-uploader/file-uploader.component';
import { TranslationService } from '../../shared/services/translation.service';

@Component({
  selector: 'app-categories-form',
  standalone: true,
  templateUrl: './categories-form.component.html',
  styleUrl: './categories-form.component.css',
  imports: [
    NzFormModule,
    ReactiveFormsModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzGridModule,
    NzUploadModule,
    NzCardModule,
    NzIconModule,
    NzSwitchModule,
    NzSelectModule,
    NzSkeletonModule,
    CommonModule,
    NzTabsModule,
    NzTableModule,
    NzDropDownModule,
    NzModalModule,
    FileUploaderComponent,
  ]
})
export class CategoriesFormComponent extends BaseComponent implements OnInit {

  isOnCreationMode: boolean = false;
  selectedTab: number = 0;
  imagesLoaded: boolean = false;
  category!: Category;
  categoryId: string | null = null;
  imagePreviewUrl: string | null = null;
  file: File | null = null;

  constructor(
    private categoryService: CategoriesService,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    router: Router,
    notification: NzNotificationService,
    translationService: TranslationService
  ) {
    super(router, notification, translationService);
  }

  ngOnInit(): void {
    this.categoryId = this.route.snapshot.params['id'];
    this.isOnCreationMode = !this.categoryId;

    this.buildForm();

    if (this.categoryId) {
      this.categoryService.findById(this.categoryId).subscribe(data => {
        this.category = {
          ...data,
          description: !data.description? data.name : data.description,
        };
        this.updateFormWithCategoryData();
      });
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      tags: [[]]
    });
  }

  private updateFormWithCategoryData(): void {
    this.form.patchValue({
      name: this.category.name,
      description: this.category.description,
      tags: this.category.tags
    });
  }

  public onSave(): void {
    if (!this.form.valid) {
      this.showFormValidations();
      return;
    }

    const formData = new FormData();
    formData.append('category', new Blob([JSON.stringify(this.form.value)], { type: 'application/json'}));

    if (this.file) {
      formData.append('image', this.file);
    }
  
    if (this.isOnCreationMode) {
      this.createCategory(formData);
    } else {
      this.updateCategory(formData)
    }
  }

  private createCategory(formaData: FormData): void {
    this.categoryService.create(formaData).subscribe({
      next: (category) => {
        this.showSavingSuccessMessage();
        this.deactivateSaving();
        this.onEdit(category.id);
      },
      error: (error) => {
        this.showSavingErrorMessage();
        this.deactivateSaving();
      }})
  }

  private updateCategory(formaData: FormData): void {

    if (!this.categoryId) {
      return;
    }

    this.categoryService.update(this.categoryId, formaData).subscribe({
      next: (category) => {
        this.showSavingSuccessMessage();
        this.deactivateSaving();
        this.onEdit(category.id);
      },
      error: (error) => {
        this.showSavingErrorMessage();
        this.deactivateSaving();
      }})
  }

  onEdit(id: string): void {
    this.router.navigateByUrl(`/categories/${id}/edit`);
  }

  onBack() {
    this.router.navigateByUrl('/categories');
  }

  onTabSelect(event: any): void {
    if (event.index === 1 && !this.imagesLoaded) {
      this.imagesLoaded = true;
    }
  }

  onFileUploaded(file: File) {
   this.file = file;
  }
}
