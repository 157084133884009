<div nz-col [nzSpan]="24">
    <form nz-form nzLayout="vertical" [formGroup]="form">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label nzRequired>Chwazi pwopriyete</nz-form-label>
              <nz-form-control>
                <nz-select formControlName="key" (ngModelChange)="onPropertyChange($event)">
                  <nz-option *ngFor="let attribute of attributes" [nzValue]="attribute.value" [nzLabel]="attribute.label"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>                                       
        <div nz-row *ngIf="selectedValue === 0">
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label nzRequired>Gwosè</nz-form-label>
              <nz-form-control>
                <nz-select formControlName="value">
                  <nz-option *ngFor="let size of sizes" [nzValue]="size" [nzLabel]="size"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div> 
        <div nz-row *ngIf="selectedValue === 1">
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label nzRequired>Koulè</nz-form-label>
              <nz-form-control nzErrorTip="Koulè a obligatwa">
                <nz-select formControlName="value" nzPlaceHolder="Chwazi yon koulè">
                  <nz-option *ngFor="let color of colors" 
                    [nzValue]="color.hex" 
                    [nzLabel]="t('products.product.colors.'+ color.hex)">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>             
    </form>
    <nz-page-header>
        <nz-page-header-extra>
          <ng-container>
            <button nz-button nzType="primary" (click)="addToTable()" [nzLoading]="saving">
              Adisyone
            </button>
          </ng-container>
        </nz-page-header-extra>
      </nz-page-header>
</div>