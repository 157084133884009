import { Image } from "../shared/model/image";
import { ProductProperty } from "../shared/model/product-property.interface";
import { Address } from "../shared/model/shipping-address.interface";



interface Product {
  id: string;
  name: string;
  description: string;
  images: Image[];
  price: number;
  properties: ProductProperty[];
  available_amount: number;
}


interface Contact {
  name: string;
  phoneNumber: string;
}

interface Customer {
  name: string;
  shipping_contact: Contact;
  shipping_address: Address;
}

export enum OrderStatus {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  PAID = "PAID",
  PROCESSING = "PROCESSING",
  SHIPPED = "SHIPPED",
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED"
}

interface OrderItemStatusLog {
  description: string;
  occurred_on: string;
  order_item_status: OrderStatus;
}

export interface ShippingTrackingData {
  company_name: string;
  company_url: string;
  tracking_id: string;
}

export interface Capability {
  [key: string]: boolean;
}

interface SellerPayoutData {
  business_name: string;
  platform_fee_percent: number;
  platform_fee_amount: number;
  payout_data: {
    amount: number;
    currency: string;
    status: "PROCESSING" | "COMPLETED" | "PENDING";
    payout_id: string;
    payout_date: string;
  };
}

export interface Order {
  id: string;
  public_id: string;
  product: Product;
  quantity: number;
  currency: string;
  status: OrderStatus;
  properties: ProductProperty[];
  customer: Customer;
  unit_price: number;
  sub_total: number;
  tax_amount: number;
  shipping_cost: number;
  unit_discount_amount: number;
  total_discount_amount: number;
  unit_price_with_discount: number;
  total_price: number;
  payment_method: string;
  order_item_status_logs: OrderItemStatusLog[];
  shipping_tracking_data?: ShippingTrackingData;
  shipment_description: string;
  created_at: string;
  capabilities: Capability;
  seller_payout_data: SellerPayoutData;
  parent: {
    shipping_cost: number;
    related_sales: [{
      itemId: string;
      itemName: string;
      quantity: number;
    }]
  },
}

export interface ChangeStatusPayload {
  status: OrderStatus,
  tracking_data?: ShippingTrackingData,
  delivery_code_confirmation?: string;
}
