import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  set(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `expires=${date.toUTCString()}`;
    }
    this.document.cookie = `${name}=${value}; ${expires}; path=/`;
  }

  remove(name: string) {
    document.cookie = `${name}=; Max-Age=-99999999;`;
  }

  get(name: string): string {
    if (typeof window === 'undefined') {
      return '';
    }
    
    const cookieMatch = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));
    return cookieMatch ? decodeURIComponent(cookieMatch[1]) : '';
  }
  
}
