<ng-container *ngIf="!loading; else skeleton">
    <div class="main-container">
        <nz-page-header
            [nzTitle]="title">
            <ng-template #title>
                {{"Adisyone varyan"}}
                <span *ngIf="form && form.dirty">*</span>
            </ng-template>
        </nz-page-header>
        <div nz-col [nzSpan]="24">
            <ng-container>
            <form nz-form nzLayout="vertical" [formGroup]="form">
                <div nz-row>
                <div nz-col [nzSpan]="24">
                    <nz-form-item>
                    <nz-form-label nzRequired>Kantite</nz-form-label>
                    <nz-form-control nzErrorTip="Kantite a obligatwa">
                        <input nz-input type="number" formControlName="quantity" />
                    </nz-form-control>
                    </nz-form-item>
                </div>
                </div>
            </form>
            </ng-container>
        </div>
        <nz-page-header>
            <nz-page-header-extra>
            <ng-container>
                <button nz-button nzType="primary" (click)="onAddToTable()" [nzLoading]="saving">
                Adisyone
                </button>
            </ng-container>
            </nz-page-header-extra>
        </nz-page-header>   
    </div>
  </ng-container>
  
  <ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
  </ng-template>
  