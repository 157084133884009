import { Component, Input } from '@angular/core';
import { Order } from '../../order.interface';
import { BaseComponent } from '../../../shared/components/ui/base-component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslationService } from '../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-customer-details',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './customer-details.component.html',
  styleUrl: './customer-details.component.css'
})
export class CustomerDetailsComponent extends BaseComponent {
  @Input() order!: Order;

  constructor(
    notificationService: NzNotificationService,
    router: Router,
    translationService: TranslationService
  ) {
    super(router, notificationService, translationService);
  }
}
