import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { ShippingTrackingData } from '../../order.interface';

@Component({
  selector: 'app-shipping-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    NzSkeletonModule,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzButtonModule,
    NzModalModule
  ],
  templateUrl: './shipping-confirmation.component.html',
  styleUrl: './shipping-confirmation.component.css'
})
export class ShippingConfirmationComponent{

  @Output() confirmShipping = new EventEmitter<ShippingTrackingData>();

  form: FormGroup;
  trackingData: any;


  constructor(
    private readonly fb: FormBuilder,
    private modal: NzModalRef,
  ){
    this.form = this.fb.group({
      company_name: ['', Validators.required],
      company_url: [''],
      tracking_id: ['', Validators.required]
    });
  }

  onConfirm() {
    if (this.form.valid) {
      this.confirmShipping.emit(this.form.value);
      this.modal.close();
    } else {
      this.form.markAllAsTouched();
    }
  }

  onCancel() {
    this.modal.close();
  }

}
