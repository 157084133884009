import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Order } from '../../order.interface';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslationService } from '../../../shared/services/translation.service';
import { Router, RouterModule } from '@angular/router';
import { BaseComponent } from '../../../shared/components/ui/base-component';

@Component({
  selector: 'app-order-item-details',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './order-item-details.component.html',
  styleUrl: './order-item-details.component.css'
})
export class OrderItemDetailsComponent extends BaseComponent {
  @Input() order !: Order;

  constructor(
    notificationService: NzNotificationService,
    router: Router,
    translationService: TranslationService
  ) {
    super(router, notificationService, translationService);
  }


  onDetail(orderId: string) {
    this.router.navigateByUrl(`orders/${orderId}/details`);
  }
  
}
