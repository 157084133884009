<ng-container>
  <nz-page-header nzTitle="{{ t('orders.title') }}"></nz-page-header>

  <div *ngIf="!loading; else skeleton">
    <div *ngIf="isDesktopView; else cardView">
      <nz-table
        #table
        [nzData]="orders"
        [nzTotal]="totalItems"
        [nzLoading]="loading"
        [nzSize]="'small'"
        [nzBordered]="true"
        [nzFrontPagination]="false"
        [nzPageSize]="pageSize"
        [nzPageIndex]="currentPage"
        (nzPageIndexChange)="onPageChange($event)"
        (nzPageSizeChange)="onPageSizeChange($event)"
        (nzQueryParams)="onQueryParamsChange($event)"
        nzShowSizeChanger
      >
        <thead>
          <tr>
            <th nzColumnKey="id" [nzSortFn]="true" nzAlign="center">
              {{ t("orders.order_id") }}
            </th>
            <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">
              {{ t("orders.created_at") }}
            </th>
            <th nzColumnKey="customer" [nzSortFn]="true" nzAlign="center">
              {{ t("orders.customer") }}
            </th>
            <th nzColumnKey="product" [nzSortFn]="true" nzAlign="center">
              {{ t("orders.product") }}
            </th>
            <th nzColumnKey="quantity" [nzSortFn]="true" nzAlign="center">
              {{ t("orders.quantity") }}
            </th>
            <th nzColumnKey="unit_price" [nzSortFn]="true" nzAlign="center">
              {{ t("orders.unit_price") }}
            </th>
            <th nzColumnKey="subtotal" [nzSortFn]="true" nzAlign="center">
              {{ t("orders.subtotal") }}
            </th>
            <th nzColumnKey="status" [nzSortFn]="true" nzAlign="center">
              {{ t("orders.status") }}
            </th>
            <th width="100px" nzAlign="center">{{ t("common.action") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let order of orders" style="cursor: pointer">
            <td (click)="onDetail(order.id)" nzAlign="center">
              {{ order.public_id }}
            </td>
            <td (click)="onDetail(order.id)" nzAlign="center">
              {{ order.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}
            </td>
            <td (click)="onDetail(order.id)" nzAlign="center">
              {{ order.customer.name }}
            </td>
            <td (click)="onDetail(order.id)" nzAlign="center">
              {{ order.product.name }}
            </td>
            <td (click)="onDetail(order.id)" nzAlign="center">
              {{ order.quantity }}
            </td>
            <td (click)="onDetail(order.id)" nzAlign="center">
              {{ order.unit_price | currency : order.currency }}
            </td>
            <td (click)="onDetail(order.id)" nzAlign="center">
              {{ order.sub_total | currency : order.currency }}
            </td>
            <td (click)="onDetail(order.id)" nzAlign="center">
              {{ translateStatus(order.status) }}
            </td>
            <td nzAlign="center">
              <button
                nz-button
                nz-dropdown
                [nzDropdownMenu]="menu"
                nzType="primary"
                [nzPlacement]="'bottomCenter'"
              >
                {{ t("common.action") }}
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>
                    <a nz-button nzType="link" (click)="onDetail(order.id)">
                      {{ t("orders.details") }}
                    </a>
                  </li>
                  <li nz-menu-item *ngIf="order.capabilities['is_processable']">
                    <a
                      nz-button
                      nz-popconfirm
                      (nzOnConfirm)="onAccept(order.id)"
                      nzPopconfirmTitle="{{
                        t('orders.are_you_sure_you_want_to_accept')
                      }}"
                      nzType="link"
                    >
                      {{ t("orders.accept") }}
                    </a>
                  </li>
                  <li
                    nz-menu-item
                    *ngIf="order.capabilities['is_shippable']"
                  >
                    <a nz-button nzType="link" (click)="onShip(order.id)">
                      {{ t("orders.ship") }}
                    </a>
                  </li>
                  <li nz-menu-item *ngIf="order.capabilities['is_deliverable']"> 
                    <a
                      nz-button
                      nzType="link"
                      nz-popconfirm
                      (nzOnConfirm)="onDeliver(order.id)"
                      nzPopconfirmTitle="{{
                        t('orders.are_you_sure_you_want_to_deliver')
                      }}"
                    >
                      {{ t("orders.deliver") }}
                    </a>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>

    <!-- Card View -->
    <ng-template #cardView >
      <div nz-row nzGutter="16" style="width: 100%;">
        <div nz-col [nzSpan]="24" *ngFor="let order of orders">
          <nz-card [nzTitle]="order.public_id" nzHoverable nzBordered style="margin-bottom: 16px;" >
            <div nz-col [nzSpan]="24">
              <div nz-row >
                <div nz-col nzSpan="12" style="padding-right: 2px;">
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;">
                      <i nz-icon nzType="calendar" nzTheme="outline"></i>
                      <strong>{{ t("orders.created_at") }}</strong>
                    </p>
                    <span>{{ order.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;">
                      <i nz-icon nzType="user" nzTheme="outline"></i>
                      <Strong>{{ t("orders.customer") }}</Strong>
                    </p>
                    <span>{{ order.customer.name }}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;">
                      <i nz-icon nzType="shopping" nzTheme="outline"></i>
                      <strong>{{ t("orders.product") }}</strong>
                    </p>
                    <span>{{ order.product.name }}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;">
                      <i nz-icon nzType="number" nzTheme="outline"></i>
                      <strong>{{ t("orders.quantity") }}</strong>
                    </p>
                    <span>{{ order.quantity }}</span>
                  </div>
                </div>
                <div nz-col nzSpan="12">
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;">
                      <i nz-icon nzType="dollar" nzTheme="outline"></i>
                      <Strong>{{ t("orders.unit_price") }}</Strong>
                    </p>
                    <span>{{ order.unit_price | currency : order.currency }}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;">
                      <i nz-icon nzType="wallet" nzTheme="outline"></i>
                      <Strong>{{ t("orders.subtotal") }}</Strong>
                    </p>
                    <span>{{ order.sub_total | currency : order.currency }}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;">
                      <i nz-icon nzType="tag" nzTheme="outline"></i>
                      <Strong>{{ t("orders.status") }}:</Strong>
                    </p>
                    <span>{{ translateStatus(order.status) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <nz-divider></nz-divider>
            <div nz-row nzJustify="center">
              <div style="display: flex; gap: 8px; flex-wrap: wrap;">
                <button nz-button nzType="primary" (click)="onDetail(order.id)">
                  {{ t("orders.details") }}
                </button>
                <button
                *ngIf="order.capabilities['is_processable']"
                  nz-button
                  nz-popconfirm
                  nzType="default"
                  (nzOnConfirm)="onAccept(order.id)"
                  nzPopconfirmTitle="{{ t('orders.are_you_sure_you_want_to_accept') }}"
                >
                  {{ t("orders.accept") }}
                </button>
                <button
                *ngIf="order.capabilities['is_shippable']"
                  nz-button
                  nzType="dashed"
                  (click)="onShip(order.id)"
                >
                  {{ t("orders.ship") }}
                </button>
                <button
                *ngIf="order.capabilities['is_deliverable']"
                  nz-button
                  nzType="primary"
                  nz-popconfirm
                  (nzOnConfirm)="onDeliver(order.id)"
                  nzPopconfirmTitle="{{ t('orders.are_you_sure_you_want_to_deliver') }}"
                >
                  {{ t("orders.deliver") }}
                </button>
              </div>
            </div>
          </nz-card>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>

<!-- Skeleton de carregamento -->
<ng-template #skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
