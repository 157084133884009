import { Component, Input, OnInit } from "@angular/core";
import { NzFormModule } from "ng-zorro-antd/form";
import { BaseComponent } from "../../../../shared/components/ui/base-component";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { NzModalModule, NzModalRef } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Variant } from "../../../../shared/model/product";
import { CommonModule } from "@angular/common";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { v4 as uuidv4 } from 'uuid';

@Component({
    standalone: true,
    selector: 'app-variant',
    templateUrl: './variant.component.html',
    styleUrls: ['./variant.component.css'],
    imports: [
        NzFormModule,
        NzFormModule,
        ReactiveFormsModule,
        NzPageHeaderModule,
        NzButtonModule,
        NzSkeletonModule,
        CommonModule,
        NzModalModule]
})
export class VariantComponent extends BaseComponent implements OnInit {

    @Input() public variant!: Variant;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private modal: NzModalRef,
        router: Router,
        notification: NzNotificationService,) {
        super(router, notification);
        this.variant = {
            attributes: [],
            quantity: 0,
            id: uuidv4()
        }
    }

    public ngOnInit(): void {
        this.buildForm();
    }


    private buildForm(): void {
        this.form = this.formBuilder.group({
          quantity: [this.variant.quantity, Validators.required],
        });
    }

    public onAddToTable() {
        if (!this.form.valid) {
            this.showFormValidations();
            return;
        }

        this.variant.quantity = this.form.get('quantity')?.value;
        this.modal.close(this.variant)
    }
}