<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    [ngClass]="{ 'hidden-sidebar': isMobile && isCollapsed }"
    nzCollapsible
    [nzWidth]="isMobile ? '256px' : (isCollapsed ? '0px' : '256px')"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="close-button" *ngIf="isMobile && !isCollapsed">
      <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="toggleSidebar()"></span>
    </div>

    <div class="sidebar-logo" *ngIf="!isCollapsed || !isMobile">
      <a>
        <img src="https://nuvann.com/_next/static/media/logo.e45e3a5f.svg" alt="logo" />
        <h1 style="margin-left: -48px;">NUVANN STORE</h1>
      </a>
    </div>

    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed && !isMobile">
      <li nz-menu-item [routerLink]="['/dashboard']" routerLinkActive="active" (click)="closeSidebar()">
        <i nz-icon nzType="dashboard"></i>
        <span *ngIf="!isCollapsed || !isMobile">{{ 'side_menu.dashboard' | t | async }}</span>
      </li>
      <li nz-menu-item *ngIf="isAdmin" [routerLink]="['/categories']" (click)="closeSidebar()">
        <i nz-icon nzType="tags"></i>
        <span *ngIf="!isCollapsed || !isMobile">{{ 'side_menu.categories' | t | async }}</span>
      </li>
      <li nz-menu-item [routerLink]="['/products']" (click)="closeSidebar()">
        <i nz-icon nzType="shopping"></i>
        <span *ngIf="!isCollapsed || !isMobile">{{ 'side_menu.products' | t | async }}</span>
      </li>
      <li
        nz-menu-item
        *ngIf="isSellerPaymentOnboardingCompleted && !isAdmin"
        (click)="openPaymentDashboard(); closeSidebar()"
      >
        <i nz-icon nzType="dollar"></i>
        <span *ngIf="!isCollapsed || !isMobile"> {{ 'side_menu.payout' | t | async  }}  <i nz-icon nzType="link"></i></span>
      </li>
      <li nz-menu-item [routerLink]="['/orders']" (click)="closeSidebar()">
        <i nz-icon nzType="shopping-cart"></i>
        <span *ngIf="!isCollapsed || !isMobile">{{ 'side_menu.orders' | t | async }}</span>
      </li>
      <li nz-menu-item [routerLink]="['/shipments']" (click)="closeSidebar()">
        <i nz-icon nzType="car"></i>
        <span *ngIf="!isCollapsed || !isMobile">{{ 'side_menu.shipments' | t | async }}</span>
      </li>
      <li nz-menu-item (click)="logoutConfirm(); closeSidebar()">
        <i nz-icon nzType="logout"></i>
        <span *ngIf="!isCollapsed || !isMobile">{{ 'side_menu.logout' | t | async }}</span>
      </li>
    </ul>
  </nz-sider>

  <nz-layout>
    <nz-header class="app-header">
      <div class="header-content">
        <span class="header-trigger" (click)="toggleSidebar()">
          <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
        </span>
        <app-user-menu class="user-menu"></app-user-menu>
      </div>
    </nz-header>

    <nz-content class="main-content">
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>
