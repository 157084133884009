import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 't',
  standalone: true,
})
export class TranslatePipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}

  transform(key: string): Observable<string> {
    return this.translationService.translations$.pipe(
      map(() => this.translationService.translate(key))
    );
  }
}
