import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { CustomAuthService } from '../auth/custom-auth.service';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { TranslationService } from '../../services/translation.service';
import { AuthUserInterface, UserRole } from '../../model/auth-user.interface';
import { PaymentsService } from '../../../payments/payments.service';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NzIconModule,
    NzLayoutModule,
    UserMenuComponent,
    RouterModule,
    NzMenuModule,
    NzModalModule,
    TranslatePipe,
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  isCollapsed = false;
  isMobile = false;
  user: AuthUserInterface | null = null;
  isAdmin: boolean = false;
  isSellerPaymentOnboardingCompleted: boolean = false;

  constructor(
    public readonly auth: CustomAuthService,
    public readonly modalService: NzModalService,
    private readonly translationService: TranslationService,
    private readonly paymentService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.checkIfMobile();
    this.loadUserDetails();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
    if (this.isMobile) {
      this.isCollapsed = true;
    }
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }

  closeSidebar() {
    if (this.isMobile) {
      this.isCollapsed = true;
    }
  }

  private loadUserDetails() {
    this.auth.authUser$.subscribe((user: AuthUserInterface) => {
      this.user = user;
      this.isAdmin = user?.roles.includes(UserRole.ADMIN);
      this.isSellerPaymentOnboardingCompleted =
        !!user?.business_account?.is_onboarding_completed;
    });
  }

  logout() {
    this.auth.logout();
  }

  public logoutConfirm(): void {
    this.modalService.confirm({
      nzTitle: 'Konfimasyon pou dekonekte',
      nzContent: `${this.user?.name}, Ou vle dekonekte vrèman?`,
      nzOkText: 'wi',
      nzCancelText: 'Non',
      nzClosable: false,
      nzOnOk: () => {
        this.logout();
      },
      nzOnCancel: () => {
        return;
      },
    });
  }

  openPaymentDashboard() {
    this.paymentService.getDashboardLoginLink().subscribe((data: any) => {
      window.open(data?.redirect_url, '_blank');
    });
  }
}