import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared/components/ui/base-component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { v4 as uuidv4 } from 'uuid';
import { Attribute, Variant } from '../../../../shared/model/product';
import { AttributeEnum } from '../../../../shared/model/property-enum';
import { NzColorPickerModule } from 'ng-zorro-antd/color-picker';
import { TranslationService } from '../../../../shared/services/translation.service';

@Component({
  selector: 'app-property',
  standalone: true,
  imports: [
    CommonModule,
    NzSkeletonModule,
    NzFormModule,
    ReactiveFormsModule,
    NzGridModule,
    NzIconModule,
    NzSwitchModule,
    NzSelectModule,
    FormsModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzTableModule,
    NzDropDownModule,
    NzModalModule,
    NzColorPickerModule
  ],
  templateUrl: './attribute-modal.component.html',
  styleUrl: './attribute-modal.component.scss'
})
export class AttributeModalComponent extends BaseComponent implements OnInit, OnDestroy {
  variant!: Variant;
  attribute!: Attribute;
  attributes = Object.keys(AttributeEnum)
    .filter(key => isNaN(Number(key)))
    .map(key => ({
      label: key,
      value: AttributeEnum[key as keyof typeof AttributeEnum]
    }));
  selectedValue!: number;
  sizes = ['XS | XP', 'P | S', 'M', 'G | L', 'GG | XL', 'XGG | XXL']
  isOnCreationMode: any;
  tableAttributes: Attribute[] = [];
  colors = [
    { name: 'White', hex: '#ffffff' },
    { name: 'Black', hex: '#000000' },
    { name: 'Red', hex: '#ff0000' },
    { name: 'Green', hex: '#008000' },
    { name: 'Blue', hex: '#0000ff' },
    { name: 'Yellow', hex: '#ffff00' },
    { name: 'Cyan', hex: '#00ffff' },
    { name: 'Magenta', hex: '#ff00ff' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Orange', hex: '#ffa500' },
    { name: 'Purple', hex: '#800080' },
    { name: 'Brown', hex: '#a52a2a' },
    { name: 'Pink', hex: '#ffc0cb' },
    { name: 'Lime', hex: '#00ff00' },
    { name: 'Indigo', hex: '#4b0082' },
    { name: 'Violet', hex: '#ee82ee' },
    { name: 'Gold', hex: '#ffd700' },
    { name: 'Silver', hex: '#c0c0c0' },
    { name: 'Beige', hex: '#f5f5dc' },
    { name: 'Teal', hex: '#008080' },
    { name: 'Navy', hex: '#000080' },
    { name: 'Olive', hex: '#808000' },
    { name: 'Coral', hex: '#ff7f50' },
    { name: 'Turquoise', hex: '#40e0d0' },
    { name: 'Lavender', hex: '#e6e6fa' },
    { name: 'Mint', hex: '#98ff98' },
    { name: 'Plum', hex: '#dda0dd' },
    { name: 'Peach', hex: '#ffdab9' }
  ];

  constructor(
    private readonly formBuilder: FormBuilder,
    private modal: NzModalRef,
    translationService: TranslationService,
    router: Router, notification: NzNotificationService) {
    super(router, notification, translationService);
    this.variant = this.modal.getConfig().nzData as Variant;
    this.attribute = {
        id: uuidv4(),
        key: '',
        value: '',
        variantId: this.variant.id
    };
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      value: [this.attribute?.value, Validators.required],
      key: [this.attribute?.key, Validators.required]
    });
  }

  public addToTable() {
    if (!this.form.valid) {
        this.showFormValidations();
        return;
    }

    this.attribute.key = this.form.get('key')?.value === 0 ? "Size" : "Color";
    this.attribute.value = this.form.get('value')?.value;
    this.modal.close(this.attribute);
  }
  
  public onPropertyChange(value: number): void {
    this.selectedValue =  value;
  }
}


