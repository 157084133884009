<div>
    <label for="delivery_code">Delivery Confirmation Code:</label>
    <input
      id="delivery_code"
      [(ngModel)]="deliveryCode"
      nz-input
      type="text"
      placeholder="Enter delivery confirmation code"
    />
    
    <div *ngIf="codeError" class="error-message">
      Invalid confirmation code. Please try again.
    </div>
  </div>