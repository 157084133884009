import { HttpEvent, HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CustomAuthService } from '../components/auth/custom-auth.service';
import { inject } from '@angular/core';

export const BearerTokenInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const authService = inject(CustomAuthService);

  const accessToken = authService.getToken();

  if (accessToken) {
    const clonedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return next(clonedReq);
  }
  return next(req);
};
