import { Component, HostListener, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/components/ui/base-component';
import { Category } from '../common/categories.interface';
import { CategoriesService } from '../categories.service';
import { CommonModule } from '@angular/common';
import { NzTableModule, NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'app-categories-list',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    NzGridModule,
    NzPageHeaderModule,
    NzDropDownModule,
    NzButtonModule,
    NzSkeletonModule,
    NzPaginationModule,
    TranslatePipe,
    NzCardModule,
    NzFlexModule,
    NzDividerModule
  ],
  templateUrl: './categories-list.component.html',
  styleUrl: './categories-list.component.css'
})
export class CategoriesListComponent extends BaseComponent implements OnInit {

  categories: Category[] = [];
  pageSize = 10;
  currentPage = 1;
  totalItems = 0;
  totalPages!: number;
  isDesktopView: boolean = window.innerWidth > 768;

  constructor(
    private readonly categoriesService: CategoriesService,
    router: Router,
    notificationService: NzNotificationService
  ) {
    super(router, notificationService);
  }

  ngOnInit(): void {
    this.loadCategories();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isDesktopView = event.target.innerWidth > 768;
  } 

  loadCategories() {
    this.activateLoading();
    this.categoriesService
        .findAll()
        .subscribe(data => {
          this.categories = data.map(category => {
            return {
              ...category,
              description: !category.description ? category.name : category.description
            }
          });
        this.deactivateLoading();
     });
  }

  onNew() {
    this.router.navigateByUrl(`categories/create`)
  }

  onEdit(categoryId: string) {
    this.router.navigateByUrl(`categories/${categoryId}/edit`)
  }

    
  onPageChange(pageIndex: number): void {
    this.currentPage = pageIndex;
    this.loadCategories();
  }
  
  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.loadCategories();
  }


  onQueryParamsChange(params: NzTableQueryParams): void {
    // Implement query params change handling if needed
  }


}
