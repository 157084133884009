import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/components/ui/base-component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Product, Variant } from '../../../shared/model/product';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ProductsService } from '../../products.services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzUploadModule, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { EMPTY, forkJoin, of, Subscription } from 'rxjs';
import { Category } from '../../../shared/model/category';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { CommonModule, formatDate, getCurrencySymbol } from '@angular/common';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { v4 as uuidv4 } from 'uuid';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { Image } from '../../../shared/model/image';
import { NzImageModule } from 'ng-zorro-antd/image';
import { VariantComponent } from '../components/variant/variant.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { AttributeModalComponent } from '../components/property-modal/attribute-modal.component';
import { ShipmentsService } from '../../../shipments/shipments.service';
import { Shipment } from '../../../shared/model/shipment';
import { TranslationService } from '../../../shared/services/translation.service';
import { CustomAuthService } from '../../../shared/components/auth/custom-auth.service';

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [
    NzFormModule,
    ReactiveFormsModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzGridModule,
    NzUploadModule,
    NzCardModule,
    NzCollapseModule,
    NzIconModule,
    NzSwitchModule,
    NzDatePickerModule,
    NzSelectModule,
    NzSkeletonModule,
    CommonModule,
    NzTabsModule,
    NzTableModule,
    NzDropDownModule,
    NzModalModule,
    NzStepsModule,
    NzUploadModule,
    NzImageModule
  ],
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  isOnCreationMode: boolean = true;
  product!: Product;
  categories: Category[] = [];
  productId!: string;
  selectedTab: number = 0;
  propertyLoaded: boolean = false;
  discountLoaded: boolean = false;
  imagesLoaded: boolean = false;
  tableVariantes: Variant[] = [];
  selectedStep = 0;
  steps = [
    { title: 'Enfòmasyon jeneral' },
    { title: 'Imaj' },
    { title: 'Enfòmasyon sou livrezon' },
    { title: 'Varyan' },
    // { title: 'Diskont' },
  ];
  fileList: any[] = [];
  images: Image[] = [];
  uploadedImages: Image[] = [];
  unsavedImages: any;
  shipments: Shipment[] = [];
  colors = [
    { name: 'White', hex: '#ffffff' },
    { name: 'Black', hex: '#000000' },
    { name: 'Red', hex: '#ff0000' },
    { name: 'Green', hex: '#008000' },
    { name: 'Blue', hex: '#0000ff' },
    { name: 'Yellow', hex: '#ffff00' },
    { name: 'Cyan', hex: '#00ffff' },
    { name: 'Magenta', hex: '#ff00ff' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Orange', hex: '#ffa500' },
    { name: 'Purple', hex: '#800080' },
    { name: 'Brown', hex: '#a52a2a' },
    { name: 'Pink', hex: '#ffc0cb' },
    { name: 'Lime', hex: '#00ff00' },
    { name: 'Indigo', hex: '#4b0082' },
    { name: 'Violet', hex: '#ee82ee' },
    { name: 'Gold', hex: '#ffd700' },
    { name: 'Silver', hex: '#c0c0c0' },
    { name: 'Beige', hex: '#f5f5dc' },
    { name: 'Teal', hex: '#008080' },
    { name: 'Navy', hex: '#000080' },
    { name: 'Olive', hex: '#808000' },
    { name: 'Coral', hex: '#ff7f50' },
    { name: 'Turquoise', hex: '#40e0d0' },
    { name: 'Lavender', hex: '#e6e6fa' },
    { name: 'Mint', hex: '#98ff98' },
    { name: 'Plum', hex: '#dda0dd' },
    { name: 'Peach', hex: '#ffdab9' }
  ];
  public tempImages: any[] = [];
  user: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private modal: NzModalService,
    private productService: ProductsService,
    private modalService: NzModalService,
    private readonly customAuthService: CustomAuthService,
    translationService: TranslationService,
    router: Router,
    notification: NzNotificationService,
    private shipmentService: ShipmentsService
  ) {
    super(router, notification, translationService);
  }

  ngOnInit(): void {
    this.activateLoading();
    const productId = this.route.snapshot.params['id'];
    this.isOnCreationMode = !productId;
    this.productId = productId;

    const sources = {
      product: this.isOnCreationMode
        ? of({} as Product)
        : this.productService.findById(productId),
      categories: this.productService.findCategories(),
      shipments: this.shipmentService.findAll(),
    };

    this.data$ = forkJoin(sources).subscribe({
      next: (value) => {
        this.product = value.product;
        this.images = value.product.images;
        this.categories = value.categories;
        this.tableVariantes = this.product.variants;
        this.shipments = value.shipments;
        this.buildProductForm();
        this.loadUser();
        this.deactivateLoading();
      },
      error: (error) => {
        console.log(error);
        this.showLoadingErrorMessage();
        this.deactivateLoading();
      },
    });
  }

  private buildProductForm(): void {
    if (!this.isOnCreationMode && !this.product) {
      return;
    }

    this.form = this.formBuilder.group({
      name: [this.product.name, Validators.required],
      available_amount: [this.product.available_amount, Validators.required],
      description: [this.product.description, Validators.required],
      price: [this.product.price, Validators.required],
      categories_id: [this.product.categories_id || [], Validators.required],
      discount_amount: [this.product.discount_amount],
      shipping_specification: this.formBuilder.group({
        width: [this.product.shipping_specification?.width || 0],
        height: [this.product.shipping_specification?.height || 0],
        length: [this.product.shipping_specification?.length || 0],
        weight: [this.product.shipping_specification?.weight || 0],
      }),
      shipments_id: [this.product.shipments_id || []],
    });
  }
  

  public onSave(): void {
    if (!this.form.valid) {
      this.showFormValidations();
      return;
    }

    if (this.isOnCreationMode && (!this.fileList || !this.fileList.length)) {
      return;
    }

    let product = this.form.value;
    product = {
      ...product,
      variants: this.transformVariants(this.tableVariantes),
    };

    const formData = new FormData();

    this.fileList.forEach((item) => {
      formData.append('files', item.file);
    });

    formData.append(
      'product',
      new Blob([JSON.stringify(product)], { type: 'application/json' })
    );

    if (this.isOnCreationMode) {
      this.createProduct(formData);
    } else {
      this.updateProduct(formData);
    }
  }

  getColorName(hex: string): string {
    const color = this.colors.find(c => c.hex === hex);
    return color ? color.name : hex;
  }

  isMobile(): boolean {
    return window.innerWidth <= 768;
  }

  private transformVariants(variants: Variant[]): any {
    return variants?.map((v) => ({
      attributes: v.attributes.map((a) => ({
        key: a.key,
        value: a.value,
      })),
      quantity: v.quantity,
    }));
  }

  previousStep(): void {
    if (this.selectedStep > 0) {
      this.selectedStep--;
    }
  }

  nextStep(): void {
    if (this.selectedStep < this.steps.length - 1) {
      this.selectedStep++;
    }
  }

  onStepChange(stepIndex: any): void {
    this.selectedStep = stepIndex;
    if (this.selectedStep === 1 && this.product?.variants?.length > 0) {
      this.onloadVariants();
    }
  }

  private createProduct(formData: FormData): void {
    this.activateSaving();
    this.productService.create(formData).subscribe({
      next: () => {
        this.showSavingSuccessMessage();
        this.deactivateSaving();
        this.form.markAsPristine();
        this.onBack();
      },
      error: (error) => {
        console.log(error);
        this.showSavingErrorMessage();
        this.deactivateSaving();
      },
    });
  }

  public onRemoveVariant(id: string): void {
    const index = this.tableVariantes.findIndex((v) => v.id === id);
    if (index !== -1) {
      this.tableVariantes.splice(index, 1);
    }
  }

  private loadUser() {
    this.customAuthService.getUserFromApi().subscribe({
      next: (userData) => {
        this.user = userData;
        console.log(this.user)
        this.deactivateLoading();
      },
      error: (err) => {
        console.error("Erro ao carregar usuário", err);
        this.deactivateLoading();
      }
    });
}      

  onEdit(id: string): void {
    this.router.navigateByUrl(`/products/${id}/edit`);
  }

  private updateProduct(formData: FormData): void {
    this.activateSaving();
  
    if (this.images && this.images.length > 0) {
      this.images.forEach((image) => {
        if (image.removed) {
          this.removeImageDefinitely(this.productId, image.id);
        }
      });
    }
  
    this.productService.update(formData, this.productId).subscribe({
      next: (product) => {
        this.product = product;
        this.showSavingSuccessMessage();
        this.form.markAsPristine();
        this.deactivateSaving();
      },
      error: (error) => {
        console.log(error);
        this.showSavingErrorMessage();
        this.deactivateSaving();
      },
    });
  }  

  public onRemove(id: string, variant: Variant): void {
    const index = variant.attributes.findIndex((attr) => attr.id === id);
    if (index !== -1) {
      variant.attributes.splice(index, 1);
    }
  }

  public onRemoveImage(id: string): void {
    const image = this.images.find(i => i.id === id);
    image!.removed = true;
  }

  public removeImageDefinitely(productId: string, imageId: string): void {
    this.productService
        .removeImage(productId, imageId)
        .subscribe({
          next: () => {},
          error(error) {
            console.log(error);
          }
        })
  }

  public confirmationMessage(id: string): void {
    this.modalService.confirm({
      nzTitle: 'Konfimasyon',
      nzContent:
        'W asirew kew vle siprime imaj sa?',
      nzOkText: 'Wi',
      nzCancelText: 'Non',
      nzClosable: false,
      nzOnOk: () => {
        this.onRemoveImage(id);
      },
      nzOnCancel: () => {
        return;
      }
    });
  }

  public onAddAttribute(variant: Variant) {
    const modal = this.modal.create({
      nzContent: AttributeModalComponent,
      nzFooter: null,
      nzData: variant,
    });

    modal.afterClose.subscribe((result) => {
      if (result) {
        if (!variant.attributes) {
          variant.attributes = [];
        }
        variant.attributes.push(result);
      }
    });
  }

  public addVariant(): void {
    const modal = this.modal.create({
      nzContent: VariantComponent,
      nzFooter: null,
    });

    modal.afterClose.subscribe((result) => {
      if (result) {
        if (!this.tableVariantes) {
          this.tableVariantes = [];
        }
        this.tableVariantes.push(result);
      }
    });
  }

  public onloadVariants(): void {
    this.tableVariantes = this.product.variants.map((p) => {
      return {
        ...p,
        id: uuidv4()
      };
    });
  }

  getCurrencySymbol(currencyCode: string): string {
    currencyCode = currencyCode != null 
        ? currencyCode 
        : this.user.business_account.currency;
    return getCurrencySymbol(currencyCode, 'narrow');
}

  public onBackConfirm(): void {
    if (this.form && this.form.dirty) {
      this.modalService.confirm({
        nzTitle: 'Konfimasyon',
        nzContent:
          'Gen chanjman ki fèt nan paj sa ki pa anrejistre. W vrèman vle kite paj la?',
        nzOkText: 'Wi',
        nzCancelText: 'Non',
        nzClosable: false,
        nzOnOk: () => {
          this.onBack();
        },
        nzOnCancel: () => {
          return;
        },
      });
    } else {
      this.onBack();
    }
  }

  onCustomRequest = (item: NzUploadXHRArgs): Subscription => {
    const reader = new FileReader();
    reader.readAsDataURL(item.file as any);
    reader.onload = () => {
      const imageUrl = reader.result as string;
  
      const newImage: Image = {
        id: '',
        title: item.file.name,
        url: imageUrl,
        alt: item.file.name,
      };
  
      this.images = [...this.images, newImage];
  
      this.fileList = [...this.fileList, { url: imageUrl, file: item.file }];
      this.form.markAsDirty();
    };
  
    return EMPTY.subscribe();
  };
  
  onBack() {
    this.router.navigateByUrl('/products');
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}