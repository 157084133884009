import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors
} from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';

import { environment as env } from '../environments/environment';

import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { CustomLanguage } from './shared/internationalization/customLanguage';
import { LOCALE_ID } from '@angular/core';
import { BearerTokenInterceptor } from './shared/interceptors/bearer-token.interceptor';
import { provideAuth0 } from '@auth0/auth0-angular';

const browserLocale = navigator.language || 'pt-US';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAuth0({
      domain: env.AUTH0_DOMAIN,
      clientId: env.AUTH0_CLIENT_ID,
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      },
      httpInterceptor: {
        allowedList: [`${env.API_URL}/*`],
      },
    }),
    provideAnimationsAsync(),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        BearerTokenInterceptor
      ])
    ),
    { provide: NZ_I18N, useValue: CustomLanguage.customLanguage },
    { provide: LOCALE_ID, useValue: browserLocale }
  ],
};
