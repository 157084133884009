import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ChangeStatusPayload, Order } from './order.interface';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private readonly API = `${env.API_URL}/orders`;


  constructor(private readonly http: HttpClient) { }

  findAll() {
    return this.http.get<Order[]>(`${this.API}/sales`);
  }


  findById(id: string) {
    return this.http.get<Order>(`${this.API}/${id}`);
  }

  changeStatus(id: string, changeStatusPayload: ChangeStatusPayload) {
    return this.http.put<Order>(`${this.API}/items/${id}/change-status`, changeStatusPayload);
  }

}
