import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { CustomAuthService } from '../shared/components/auth/custom-auth.service';
import { PaymentsService } from '../payments/payments.service';
import { AuthUserInterface, UserRole } from '../shared/model/auth-user.interface';
import { Router } from '@angular/router';
import { TranslationService } from '../shared/services/translation.service';
import { BaseComponent } from '../shared/components/ui/base-component';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { ProductsService } from '../products/products.services';
import { OrdersService } from '../orders/orders.service';
import { combineLatest, forkJoin } from 'rxjs';
import { OrderStatus } from '../orders/order.interface';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzProgressModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzPageHeaderModule,
    NzLayoutModule,
    NzTableModule,
    NzNotificationModule,
    NzSkeletonModule,
    NzFormModule,
    NzAlertModule,
    NzTabsModule,
    NzSelectModule,
    NzSwitchModule,
    NzUploadModule,
    NzImageModule,
    NzDividerModule,
    NzPopconfirmModule,
    NzDropDownModule,
    NzSpaceModule,
    NzCollapseModule,
    NzTagModule,
    NzCardModule,
    NzEmptyModule,
    NzListModule,
    NzStatisticModule
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent extends BaseComponent implements OnInit {
  user: AuthUserInterface | null = null;
  isAdmin: boolean = false;
  isSellerPaymentOnboardingCompleted: boolean = false;
  userLoaded: boolean = false;
  pageSize = 10;
  currentPage = 1;
  totalProducts!: number;
  totalOrders!: number;
  totalShipments!: number;
  deliveredOrders: number = 0;
  pendingOrders: number = 0;


  constructor(
    private readonly customAuthService: CustomAuthService,
    private readonly paymentService: PaymentsService,
    private productService: ProductsService,
    private readonly orderService: OrdersService,
    router: Router,
    notificationService: NzNotificationService,
    translationService: TranslationService
  ) {
    super(router, notificationService, translationService);
  }

  ngOnInit(): void {
    this.activateLoading();
    this.loadData();
  }

  private loadData() {
    const sources = {
      user: this.customAuthService.authUser$,
      products: this.productService.findAll(this.currentPage, this.pageSize),
      orders: this.orderService.findAll()
    };
    this.data$ = combineLatest(sources)
      .subscribe({
        next: value => {
          this.user = value.user;
          this.isAdmin = value.user?.roles.includes(UserRole.ADMIN);
          this.isSellerPaymentOnboardingCompleted = !!value.user?.business_account?.is_onboarding_completed;
          this.totalProducts = value.products.total_items;
          if (!!value.orders) {
            this.totalOrders = value.orders.length;
            this.pendingOrders = value.orders.filter(order => ![OrderStatus.CANCELLED, OrderStatus.DELIVERED].includes(order.status))
                                  .length
            this.deliveredOrders = value.orders.filter(order => order.status === OrderStatus.DELIVERED)
                                  .length

          }
          this.userLoaded = true; 
          this.deactivateLoading();
        }
      });
  }

  setupPayout() {
    this.activateSaving();
    this.paymentService.setupPayout().subscribe((data: any) => {
      window.location.href = data?.url;
      this.deactivateSaving();
    });
  }
}