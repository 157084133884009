import { Component, HostListener, OnInit } from '@angular/core';
import { OrdersService } from '../orders.service';
import { Order, OrderStatus, ShippingTrackingData } from '../order.interface';
import { NzTableModule, NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { BaseComponent } from '../../shared/components/ui/base-component';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CommonModule } from '@angular/common';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { TranslationService } from '../../shared/services/translation.service';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ShippingConfirmationComponent } from '../components/shipping-confirmation/shipping-confirmation.component';
import { AuthUserInterface, UserRole } from '../../shared/model/auth-user.interface';
import { CustomAuthService } from '../../shared/components/auth/custom-auth.service';
import { NzCardComponent } from 'ng-zorro-antd/card';
import { DeliveryCodeConfirmationComponent } from '../components/delivery-code-confirmation/delivery-code-confirmation.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'app-orders-list',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    NzGridModule,
    NzPageHeaderModule,
    NzDropDownModule,
    NzButtonModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzPopconfirmModule,
    NzModalModule,
    NzCardComponent,
    NzDividerModule,
  ],
  templateUrl: './orders-list.component.html',
  styleUrl: './orders-list.component.css',
})
export class OrdersListComponent extends BaseComponent implements OnInit {
  pageSize = 10;
  currentPage = 1;
  totalItems = 0;
  totalPages!: number;
  isAdmin: boolean = false;
  isDesktopView: boolean = window.innerWidth > 768;
  
  orders: Order[] = [];

  constructor(
    private readonly orderService: OrdersService,
    router: Router,
    notificationService: NzNotificationService,
    translationService: TranslationService,
    private modal: NzModalService,
    private readonly authService: CustomAuthService
  ) {
    super(router, notificationService, translationService);
  }

  ngOnInit(): void {
    this.activateLoading();
    this.isDesktopView = window.innerWidth > 768;
    this.loadOrders();
    this.loadUser()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isDesktopView = event.target.innerWidth > 768;
  }

  private loadOrders() {
    this.orderService.findAll().subscribe((data) => {
      this.orders = data;
      this.deactivateLoading();
    });
  }

  loadUser() {
    this.authService.authUser$.subscribe((user: AuthUserInterface) => {
      this.isAdmin = user?.roles.includes(UserRole.ADMIN);
    });
  }

  onDetail(orderId: string) {
    this.router.navigateByUrl(`orders/${orderId}/details`);
  }

  onPageChange(pageIndex: number): void {
    this.currentPage = pageIndex;
    this.loadOrders();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.loadOrders();
  }

  goToList() {
    this.router.navigateByUrl('/orders');
  }

  translateStatus(status: OrderStatus): string {
    const statuses = {
      [OrderStatus.AWAITING_PAYMENT]: this.t(
        'orders.statuses.awaiting_payment'
      ),
      [OrderStatus.PAID]: this.t('orders.statuses.paid'),
      [OrderStatus.PROCESSING]: this.t('orders.statuses.processing'),
      [OrderStatus.SHIPPED]: this.t('orders.statuses.shipped'),
      [OrderStatus.CANCELLED]: this.t('orders.statuses.cancelled'),
      [OrderStatus.DELIVERED]: this.t('orders.statuses.delivered'),
    };
    return statuses[status] || status;
  }

  getStatusClasses(order: any): any {
    return {
      'status-badge': true,
      [order.status.toLowerCase()]: true
    };
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // Implement query params change handling if needed
  }

  onAccept(id: string) {
    this.orderService
      .changeStatus(id, { status: OrderStatus.PROCESSING })
      .subscribe({
        next: () => {
          this.showSavingSuccessMessage();
          this.loadOrders();
        },
        error: (error) => {
          this.showSavingErrorMessage(error?.error?.message);
        },
      });
  }


  onShip(id: string) {
    const modalRef: NzModalRef = this.modal.create({
      nzTitle: 'Confirm Shipping',
      nzContent: ShippingConfirmationComponent,
      nzOkText: 'Ship Order',
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        return new Promise<void>((resolve, reject) => {
          const modalComponentInstance = modalRef.getContentComponent();

          const { company_name, tracking_id } =
            modalComponentInstance.form.value;

          if (company_name && tracking_id) {
            this.orderService
              .changeStatus(id, {
                status: OrderStatus.SHIPPED,
                tracking_data: modalComponentInstance.form.value,
              })
              .subscribe({
                next: () => {
                  this.showSavingSuccessMessage();
                  this.loadOrders();
                  resolve();
                },
                error: (error) => {
                  this.showSavingErrorMessage(error?.error?.message);
                  reject();
                },
              });
          } else {
            reject();
            alert('Please fill in all shipping information fields.');
          }
        });
      },
      nzOnCancel: () => {
        console.log('Shipping action canceled.');
      },
    });
  }

  /*
  onDeliver(id: string) {
    this.orderService
    .changeStatus(id, { status: OrderStatus.DELIVERED })
    .subscribe({
      next: () => {
        this.showSavingSuccessMessage();
        this.loadOrders();
      },
      error: (error) => {
        this.showSavingErrorMessage(error?.error?.message);
      },
    });
  }
    */

  onDeliver(id: string) {
    const modalRef: NzModalRef = this.modal.create({
      nzTitle: 'Confirm Delivery',
      nzContent: DeliveryCodeConfirmationComponent,
      nzOkText: 'Deliver Order',
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        return new Promise<void>((resolve, reject) => {

          const modalComponentInstance = modalRef.getContentComponent();
          
          const { deliveryCode } = modalComponentInstance;

          if (deliveryCode) {
            this.orderService
              .changeStatus(id, {
                status: OrderStatus.DELIVERED,
                delivery_code_confirmation: deliveryCode
              })
              .subscribe({
                next: () => {
                  this.showSavingSuccessMessage();
                  this.loadOrders();
                  resolve();
                },
                error: (error) => {
                  this.showSavingErrorMessage(error?.error?.message);
                  reject();
                },
              });
          } else {
            reject();
            alert('Invalid confirmation code. Please try again.');
          }
        });
      },
      nzOnCancel: () => {
        console.log('Deliver action canceled.');
      },
    });
  }
  
}
