import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  private readonly API = `${env.API_URL}/management/payment`;

  constructor(private readonly http: HttpClient) {}

  setupPayout() {
    return this.http.post(`${this.API}/onboarding`,{
      refresh_url: env.STRIPE_REFRESH_URL,
      return_url: env.STRIPE_RETURN_URL
    })
  }

  getDashboardLoginLink() {
    return this.http.get(`${this.API}/dashboard/login-link`)
  }
}
