
export const environment = {
  production: false,
  NODE_ENV: 'development',
  API_URL: 'https://dev-api.nuvann.com/api/v1',
  STORE_URL: 'https://staging.nuvann.com',
  SELLER_ENDPOINT: 'https://staging.nuvann.com?becomeseller=true',
  AUTH0_DOMAIN: 'https://dev-oy36i2x64cnh58cs.us.auth0.com',
  AUTH0_CLIENT_ID: 'REkkV6Q8tU37f7DavDbNIbyU9wC21r1W',
  AUTH0_AUDIENCE: 'https://dev-oy36i2x64cnh58cs.us.auth0.com/api/v2/',
  STRIPE_REFRESH_URL: 'https://staging-manage.nuvann.com/dashboard',
  STRIPE_RETURN_URL: 'https://staging-manage.nuvann.com/',
};
