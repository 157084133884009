import { Component, OnInit } from '@angular/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { Router } from '@angular/router';
import { CustomAuthService } from '../auth/custom-auth.service';
import { AuthUserInterface } from '../../model/auth-user.interface';

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [NzDropDownModule, NzAvatarModule],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit {
  user: AuthUserInterface | null = null;

  constructor(
    private readonly customAuthService: CustomAuthService
  ) { }

  ngOnInit(): void {
    this.loadUserDetails();
  }

  private loadUserDetails() {
    this.customAuthService.authUser$.subscribe(user => {
      this.user = user;
    });
  }
}
