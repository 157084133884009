import { Component, EventEmitter, Output } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [    
    NzFormModule,
  ],
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.css'
})
export class FileUploaderComponent {
  @Output() fileUploaded = new EventEmitter<File>();
  file: File | undefined;

  onFileChange(event: any) {
    this.file = event.target.files[0];
    this.fileUploaded.emit(this.file);
  }
}
