import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@Component({
  selector: 'app-delivery-code-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    NzSkeletonModule,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzButtonModule,
    NzModalModule
  ],
  templateUrl: './delivery-code-confirmation.component.html',
  styleUrl: './delivery-code-confirmation.component.css'
})
export class DeliveryCodeConfirmationComponent {
  deliveryCode: string = '';
  codeError: boolean = false;
}
