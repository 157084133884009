import { CommonModule, getCurrencySymbol } from "@angular/common";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { BaseComponent } from "../../shared/components/ui/base-component";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { NzModalModule, NzModalService } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Country, ShipmentPayload } from "../../shared/model/shipment";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSelectModule } from "ng-zorro-antd/select";
import { ShipmentEnum } from "../../shared/model/shipment-enum";
import { ShipmentsService } from "../shipments.service";
import { DeadlineFrequency } from "../../shared/model/deadLineFrequency";
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { CustomAuthService } from "../../shared/components/auth/custom-auth.service";
import { forkJoin } from "rxjs";

@Component({
    selector: 'app-shipment',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        CommonModule,
        NzCheckboxModule,
        NzButtonModule,
        NzPaginationModule,
        NzIconModule,
        NzModalModule,
        NzStepsModule,
        NzGridModule,
        NzFormModule,
        ReactiveFormsModule,
        NzSelectModule,
        NzDividerModule,
        NzSwitchModule,
        NzToolTipModule
    ],
    templateUrl: './shipment.component.html',
    styleUrl: './shipment.component.css'
})
export class ShipmentComponent extends BaseComponent implements OnInit, OnDestroy {

    isOnCreationMode: boolean = true;
    minLimits = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    maxLimits = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    selectedValue!: number;
    frequencyValue!: number;
    shipment!: ShipmentPayload;
    selectedStep = 0;
    steps = [
        { title: 'Enfòmasyon jeneral' },
        { title: 'Others' }
    ];
    translationMap: { [key in ShipmentEnum]: string } = {
        [ShipmentEnum.FIXED_SHIPPING]: 'Livrezon fiks',
        [ShipmentEnum.PER_UNIT_SHIPPING]: 'Livrezon pa inite'
    };

    types = Object.keys(ShipmentEnum)
        .filter(key => isNaN(Number(key))) 
        .map(key => ({
            label: this.translationMap[ShipmentEnum[key as keyof typeof ShipmentEnum] as ShipmentEnum],
            value: ShipmentEnum[key as keyof typeof ShipmentEnum] 
        }));

    frequencies = Object.keys(DeadlineFrequency)
        .filter(key => isNaN(Number(key)))
        .map(key => ({
            label: key,
            value: DeadlineFrequency[key as keyof typeof DeadlineFrequency]
        }));

    countries: Country[] = [
        // { code: 'CA', name: 'Canadá' },
        // { code: 'CL', name: 'Chile' },
        // { code: 'HT', name: 'Haiti' },
        { code: 'BR', name: 'Brasil' },
        { code: 'US', name: 'Unied States' },
    ];

    shipmentId: string | null = null;
    user: any;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private shipmentsService: ShipmentsService,
        private modal: NzModalService,
        private readonly customAuthService: CustomAuthService,
        private modalService: NzModalService,
        router: Router,
        notification: NzNotificationService,) {
        super(router, notification);
        this.shipment = {
            description: '',
            price: 0,
            type: ShipmentEnum.FIXED_SHIPPING,
            deadline: { minLimit: 0, maxLimit: 0, minFrequency: DeadlineFrequency.HOURS, maxFrequency: DeadlineFrequency.HOURS },
            coverage_area: { country: { code: '', name: '' }, stateOrDepartment: '', city: '', neighborhood: '' },
            discount_amount: 0,
            is_available_for_all_products: false,
            additional_price_per_unit: 0,
            discount_quantity_threshold: 0,
            is_active: true,
            currency: ''
        };
    }

    public ngOnInit(): void {
        this.buildForm();
        this.loadUser();
        this.shipmentId = this.route.snapshot.paramMap.get('id');

        if (this.shipmentId) {
            this.isOnCreationMode = false;
            this.loadShipmentData();
        }
    }

    private loadShipmentData(): void {
        this.shipmentsService.findAll().subscribe(data => {
            const foundShipment = data.find(shipment => shipment.id === this.shipmentId);
            if (!foundShipment) {
              return;
            } 

            this.shipment = foundShipment;
            this.updateForm();
        });
    }

    private loadUser() {      
        this.customAuthService.getUserFromApi().subscribe({
          next: (userData) => {
            this.user = userData;
            this.deactivateLoading();
          },
          error: (err) => {
            console.error("Erro ao carregar usuário", err);
            this.deactivateLoading();
          }
        });
    }      

    private updateForm(): void {
        this.form.patchValue({
            description: this.shipment.description,
            price: this.shipment.base_price || this.shipment.price,
            type: this.shipment.type,
            discount_amount: this.shipment.discount_amount,
            is_available_for_all_products: this.shipment.is_available_for_all_products,
            is_active: this.shipment.is_active,
            minLimit: this.shipment.deadline.minLimit,
            maxLimit: this.shipment.deadline.maxLimit,
            minFrequency: this.shipment.deadline.minFrequency,
            maxFrequency: this.shipment.deadline.maxFrequency,
            country: this.shipment.coverage_area.country.code,
            stateOrDepartment: this.shipment.coverage_area.stateOrDepartment,
            city: this.shipment.coverage_area.city,
            neighborhood: this.shipment.coverage_area.neighborhood
        });
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({
            description: [this.shipment.description, Validators.required],
            price: [this.shipment.price, [Validators.required, Validators.min(0)]],
            type: [this.shipment.type, Validators.required],
            discount_amount: [this.shipment.discount_amount, Validators.min(0)],
            is_available_for_all_products: [this.shipment.is_available_for_all_products],
            is_active: [this.shipment.is_active],
            minLimit: [this.shipment.deadline.minLimit, Validators.required],
            maxLimit: [this.shipment.deadline.maxLimit, Validators.required],
            minFrequency: [this.shipment.deadline.minFrequency, Validators.required],
            maxFrequency: [this.shipment.deadline.maxFrequency, Validators.required],
            country: [this.shipment.coverage_area.country.code],
            stateOrDepartment: [this.shipment.coverage_area.stateOrDepartment],
            city: [this.shipment.coverage_area.city],
            neighborhood: [this.shipment.coverage_area.city]
        });
    }

    getFormattedDeadline(): string {
        const translations: { [key in DeadlineFrequency]: string } = {
            [DeadlineFrequency.HOURS]: 'H',
            [DeadlineFrequency.DAYS]: 'J',
            [DeadlineFrequency.WEEK]: 'Semèn',
            [DeadlineFrequency.MONTH]: 'Mwa'
        };
    
        const minLimit = this.form.get('minLimit')?.value;
        const minFrequency: DeadlineFrequency = this.form.get('minFrequency')?.value;
        const maxLimit = this.form.get('maxLimit')?.value;
        const maxFrequency: DeadlineFrequency = this.form.get('maxFrequency')?.value;
    
        if (minLimit && minFrequency && maxLimit && maxFrequency) {
            return `Antre ${minLimit} ${translations[minFrequency]} a ${maxLimit} ${translations[maxFrequency]}`;
        }
        return '';
    }
    
    getCurrencySymbol(currencyCode: string): string {
        currencyCode = currencyCode != null 
            ? currencyCode 
            : this.user.business_account.currency;
        return getCurrencySymbol(currencyCode, 'narrow');
    }

    onStepChange(stepIndex: any): void {
        this.selectedStep = stepIndex;
    }

    public onSave(): void {
        if (!this.form.valid) {
            this.showFormValidations();
            return;
        }

        const formData = this.form.value;

        const shipment: ShipmentPayload = {
            description: formData.description,
            price: formData.price,
            type: formData.type,
            discount_amount: formData.discount_amount,
            is_available_for_all_products: formData.is_available_for_all_products,
            is_active: formData.is_active,
            deadline: {
                minLimit: formData.minLimit,
                maxLimit: formData.maxLimit,
                minFrequency: formData.minFrequency,
                maxFrequency: formData.maxFrequency
            },
            coverage_area: {
                country: {
                    code: formData.country,
                    name: "Brasil"
                },
                stateOrDepartment: formData.stateOrDepartment,
                city: formData.city,
                neighborhood: formData.neighborhood
            },
            additional_price_per_unit: 0,
            discount_quantity_threshold: 0,
            currency: ''
        }

        if (this.isOnCreationMode) {
            this.create(shipment);
        } else {
            this.update(shipment);
        }
    }

    onBack() {
        this.router.navigateByUrl('/shipments');
    }

    public onPropertyChange(value: number): void {
        this.selectedValue = value;
    }

    public onChangeFrequency(value: number): void {
        this.frequencyValue =  value;
    }

    private create(shipment: ShipmentPayload): void {
        this.activateSaving();
        this.shipmentsService
            .create(shipment)
            .subscribe({
                next: (shipment) => {
                    this.showSavingSuccessMessage();
                    this.deactivateSaving();
                    this.onBack();
                },
                error: (error) => {
                    this.showSavingErrorMessage();
                    this.deactivateSaving();
                }
            });
    }

    private update(shipment: ShipmentPayload): void {

        if (!this.shipmentId) return;

        this.activateSaving();
        this.shipmentsService
            .update(this.shipmentId, shipment)
            .subscribe({
                next: (shipment) => {
                    this.showSavingSuccessMessage();
                    this.deactivateSaving();
                },
                error: (error) => {
                    this.showSavingErrorMessage();
                    this.deactivateSaving();
                }
            });
    }
}