<ng-container>
    <nz-page-header nzTitle="{{ 'categories.title' | t | async }}">
        <nz-page-header-extra>
            <button nz-button nzType="primary" (click)="onNew()">
              {{ 'common.new' | t | async }}
            </button>
        </nz-page-header-extra>
    </nz-page-header>

    <div nz-row style="margin-top: 8px;" *ngIf="!loading; else skeleton">
      <div nz-col [nzSpan]="24" *ngIf="isDesktopView; else cardView">
          <nz-table #table [nzData]="categories" [nzTotal]="totalItems"
              [nzLoading]="loading" [nzSize]="'small'" [nzBordered]="true" [nzFrontPagination]="false"
              [nzPageSize]="pageSize" [nzPageIndex]="currentPage"
              (nzPageIndexChange)="onPageChange($event)"
              (nzPageSizeChange)="onPageSizeChange($event)"
              (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger>
              <thead>
                <tr>
                  <th nzColumnKey="id" [nzSortFn]="true" nzAlign="center">Id</th>
                  <th nzColumnKey="name" [nzSortFn]="true" nzAlign="center">{{ 'common.name' | t | async }}</th>
                  <th nzColumnKey="price" [nzSortFn]="true" nzAlign="center">{{ 'common.description' | t | async }}</th>
                  <th width="100px" nzAlign="center">{{ 'common.action' | t | async }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let category of categories; index as i" style="cursor: pointer;">
                  <td (click)="onEdit(category.id)" nzAlign="center">{{ (currentPage - 1) * pageSize + i + 1 }}</td>
                  <td (click)="onEdit(category.id)" nzAlign="center">{{ category.name }}</td>
                  <td (click)="onEdit(category.id)" nzAlign="center">{{ category.description }}</td>
                  <td nzAlign="center">
                    <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary" [nzPlacement]="'bottomCenter'">
                      {{ 'common.action' | t | async }}
                    </button>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item>
                          <a nz-button nzType="link" (click)="onEdit(category.id)">
                            {{ 'common.edit' | t | async }}
                          </a>
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </td>
                </tr>
              </tbody>
          </nz-table>
      </div>

          <!-- Card View -->
    <ng-template #cardView >
      <div nz-row nzGutter="16" style="width: 100%;">
        <div nz-col [nzSpan]="24" *ngFor="let category of categories;">
          <nz-card nzBordered nzHoverable style="margin-bottom: 16px;" >
            <div nz-col [nzSpan]="24">
              <div nz-row >
                <div nz-col nzSpan="24" style="padding-right: 2px;">
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Id</Strong></p>
                    <span>{{category.id}}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>{{ 'common.name' | t | async }}</Strong></p>
                    <span>{{ category.name}}</span>
                  </div>
               
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>{{ 'common.description' | t | async }}</Strong></p>
                    <span>{{category.description}}</span>
                  </div>
                </div>
               
              </div>
            </div>
            <nz-divider></nz-divider>
            <div nz-row nzJustify="center">
              <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary" [nzPlacement]="'bottomCenter'">
                {{ 'common.action' | t | async }}
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>
                    <a nz-button nzType="link" (click)="onEdit(category.id)">
                      {{ 'common.edit' | t | async }}
                    </a>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </nz-card>
        </div>
      </div>
    </ng-template>
  </div> 
  
  
  </ng-container>

  <ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
  </ng-template>
  