<div class="order">
    <header>
        <strong>{{ t('orders.order_details')}}</strong>
    </header>

    <div class="order-infos">
        <div class="order-item">
            <div class="item-details">
                <img src="{{ order.product.images[0].url }}" alt="{{ order.product.images[0].alt }}" class="product-image">
                <div>
                    <h4 class="product-name">{{ order.product.name }}</h4>
                    <div *ngIf="order.product?.properties">
                        <div *ngFor="let property of order.product.properties" class="product-property">
                            <p *ngIf="property.key === 'color'">
                                {{ property.key }}:  {{ t('products.product.colors.'+ property.value) }}
                            </p>
                            <p *ngIf="property.key !== 'color'">
                                {{ property.key }}: {{ property.value }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p class="item-quantity">{{ t('orders.quantity')}}: {{ order.quantity }}</p>
            <p class="item-price">{{ t('orders.unit_price') }}: {{ order.unit_price | currency: order.currency }}</p>
        </div>
        <hr class="divider">

        <div class="order-summary">
            <h3>{{ t('orders.order_summary')}}</h3>
            <div class="summary-item">
                <span>{{ t('orders.subtotal')}}:</span>
                <span>{{ order.sub_total | currency: order.currency }}</span>
            </div>
            <div class="summary-item">
                <span>{{ t('orders.discount_applied')}}:</span>
                <span>{{ order.total_discount_amount | currency: order.currency }}</span>
            </div>
            <div class="summary-item">
                <span>{{ t('orders.shipping_fee')}}:</span>
                <span>{{ order.shipping_cost | currency: order.currency }}</span>
            </div>
            <div class="summary-item">
                <span>{{ t('orders.tax')}}:</span>
                <span>{{ order.tax_amount | currency: order.currency }}</span>
            </div>
            <div class="summary-item total">
                <span>{{ t('orders.total')}}:</span>
                <span>{{ order.total_price | currency: order.currency }}</span>
            </div>
            <div class="summary-item">
                <span>{{ t('orders.amount_paid')}}:</span>
                <span>{{ order.total_price | currency: order.currency }}</span>
            </div>
        </div> 

        <hr>
        <div>
            <h5>{{ t('orders.related_item_that_use_the_same_shipping')}}</h5>
            <li *ngFor="let item of order.parent.related_sales">
                <a 
                  [href]="'/orders/' + item.itemId + '/details'" 
                  target="_blank" 
                  rel="noopener noreferrer">
                  {{ item.itemName }} ({{ t('orders.quantity') }}: {{ item.quantity }})
                </a>
              </li>
        </div>
    </div>
</div>