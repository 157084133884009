<ng-container>
  <nz-page-header nzTitle="Livrezon">
    <nz-page-header-extra>
      <input 
        *ngIf="isSearchVisible"
        nz-input
        placeholder="Chèche yon pwodui..."
        style="margin-right: 16px; width: 200px;">
      <span
        style="margin-right: 24px; cursor: pointer;"
        nz-icon
        nzType="search"
        nzTheme="outline"
        (click)="toggleSearch()">
      </span>
      <button nz-button nzType="primary" (click)="onNew()">
        {{ 'Nouvo' }}
      </button>
    </nz-page-header-extra>    
  </nz-page-header>

  <div nz-row style="margin-top: 8px;" *ngIf="!loading; else skeleton">
    <div nz-col [nzSpan]="24" *ngIf="isDesktopView; else cardView">
        <nz-table #table [nzData]="shipments" [nzTotal]="totalItems"
            [nzLoading]="loading" [nzSize]="'small'" [nzBordered]="true" [nzFrontPagination]="false"
            [nzPageSize]="pageSize" [nzPageIndex]="currentPage"
            (nzPageIndexChange)="onPageChange($event)"
            (nzPageSizeChange)="onPageSizeChange($event)"
            (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger>
            <thead>
              <tr>
                <th nzColumnKey="id" [nzSortFn]="true" nzAlign="center">Id</th>
                <th nzColumnKey="description" [nzSortFn]="true" nzAlign="center">Tip</th>
                <th nzColumnKey="type" [nzSortFn]="true" nzAlign="center">Deskripsyon</th>
                <th nzColumnKey="base_price" [nzSortFn]="true" nzAlign="center">Pri</th>
                <th nzColumnKey="coverage_area" [nzSortFn]="true" nzAlign="center">Zòn disponib</th>
                <th nzColumnKey="deadline" [nzSortFn]="true" nzAlign="center">Delè</th>
                <th width="100px" nzAlign="center">Aksyon</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let shipment of shipments; index as i" style="cursor: pointer;">
                <td (click)="onEdit(shipment.id)" nzAlign="center">{{ (currentPage - 1) * pageSize + i + 1 }}</td>
                <td (click)="onEdit(shipment.id)" nzAlign="center">{{ getShipmentTypeTranslation(shipment.type) }}</td>
                <td (click)="onEdit(shipment.id)" nzAlign="center">{{ shipment.description }}</td>
                <td (click)="onEdit(shipment.id)" nzAlign="center">{{ shipment.base_price | currency: shipment.currency}}</td>
                <td (click)="onEdit(shipment.id)" nzAlign="center">{{ getFormattedCoverage(shipment) }}</td>
                <td (click)="onEdit(shipment.id)" nzAlign="center">{{ getFormattedDeadline(shipment) }}</td>
                <td nzAlign="center">
                  <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary" [nzPlacement]="'bottomCenter'">
                    {{ 'Aksyon'}}
                  </button>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item>
                        <a nz-button nzType="link" (click)="onEdit(shipment.id)">
                          {{ 'Modifye' }}
                        </a>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
              </tr>
            </tbody>
        </nz-table>
    </div>

    <!-- Card View -->
    <ng-template #cardView >
      <div nz-row nzGutter="16" style="width: 100%;">
        <div nz-col [nzSpan]="24" *ngFor="let shipment of shipments; index as i">
          <nz-card nzBordered nzHoverable style="margin-bottom: 16px;" >
            <div nz-col [nzSpan]="24">
              <div nz-row >
                <div nz-col nzSpan="12" style="padding-right: 2px;">
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Id</Strong></p>
                    <span>{{shipment.id}}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Pri</Strong></p>
                    <span>{{ shipment.base_price | currency: shipment.currency}}</span>
                  </div>
               
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Deskripsyon</Strong></p>
                    <span>{{shipment.description}}</span>
                  </div>
                </div>
                <div nz-col nzSpan="12">
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Tip</Strong></p>
                    <span>{{shipment.type}}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Zòn disponib</Strong></p>
                    <span>{{ getFormattedCoverage(shipment) }}</span>
                  </div>
                  <div style="margin-bottom: 16px;">
                    <p style="font-weight: 400; margin: 0;"><Strong>Delè</Strong></p>
                    <span>{{ getFormattedDeadline(shipment) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <nz-divider></nz-divider>
            <div nz-row nzJustify="center">
              <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary" [nzPlacement]="'bottomCenter'">
                {{ 'Aksyon'}}
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>
                    <a nz-button nzType="link" (click)="onEdit(shipment.id)">
                      {{ 'Modifye' }}
                    </a>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </nz-card>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
