import { Component, HostListener, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ProductsService } from './../../products.services';
import { CommonModule } from '@angular/common';
import { NzTableModule, NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { BaseComponent } from '../../../shared/components/ui/base-component';
import { Router } from '@angular/router';
import { Product } from '../../../shared/model/product';
import { Page } from '../../../shared/model/pagination';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import { ProductStatus } from '../../product.types';
import { CustomAuthService } from '../../../shared/components/auth/custom-auth.service';
import { AuthUserInterface, UserRole } from '../../../shared/model/auth-user.interface';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { PaymentsService } from '../../../payments/payments.service';
import { TranslationService } from '../../../shared/services/translation.service';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@Component({
  selector: 'app-products, nz-demo-flex-basic',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    NzGridModule,
    NzPageHeaderModule,
    NzDropDownModule,
    NzButtonModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzIconModule,
    NzEmptyModule,
    NzCardModule,
    NzFlexModule,
    NzDividerModule
  ]
})
export class ProductsComponent extends BaseComponent implements OnInit, OnDestroy {
  products: Product[] = [];
  pageSize = 10;
  currentPage = 1;
  totalItems = 0;
  totalPages!: number;
  isSearchVisible = false;
  authUser: AuthUserInterface | undefined;
  isAdmin: boolean = false;
  isDesktopView: boolean = window.innerWidth > 768;
  isVertical: boolean = true;
  isSellerPaymentOnboardingCompleted: boolean = false;

  constructor(
    private productService: ProductsService,
    private readonly paymentService: PaymentsService,
    router: Router,
    notificationService: NzNotificationService,
    private readonly authService: CustomAuthService,
    translationService: TranslationService
  ) {
    super(router, notificationService, translationService);
  }

  ngOnInit(): void {
    this.isDesktopView = window.innerWidth > 768;
    this.loadProducts(this.currentPage, this.pageSize);
    this.setUser();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isDesktopView = event.target.innerWidth > 768;
  } 

  loadProducts(currentPage: number, pageSize: number): void {
    this.loading = true;
    this.productService.findAll(currentPage, pageSize)
      .subscribe(
        (page: Page<Product>) => {
          this.products = page.items;
          this.totalItems = page.total_items;
          this.loading = false;
        },
        (error) => {
          console.error('Erro ao carregar produtos:', error);
          this.loading = false;
        }
      );
  }

  setUser() {
    this.authService.authUser$.subscribe((user: AuthUserInterface) => {
      this.authUser = user;
      this.isAdmin = user?.roles.includes(UserRole.ADMIN);
      this.isSellerPaymentOnboardingCompleted = !!user?.business_account?.is_onboarding_completed;
    });
  }

  setupPayout() {
    this.paymentService.setupPayout().subscribe((data: any) => {
      window.location.href = data?.url;
    });
  }

  toggleSearch(): void {
    this.isSearchVisible = !this.isSearchVisible;
  }
  
  onPageChange(pageIndex: number): void {
    this.currentPage = pageIndex;
    this.loadProducts(this.currentPage, this.pageSize);
  }
  
  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.loadProducts(this.currentPage, this.pageSize);
  }
  
  onEdit(id: string): void {
    this.router.navigateByUrl(`/products/${id}/edit`);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // Implement query params change handling if needed
  }

  onNew(): void {
    if (this.isSellerPaymentOnboardingCompleted) {
      this.router.navigateByUrl('/products/create');
    } else {
      this.notificationService.warning(
        'Oupps', 
        this.t('products.new_product_warning'));
        this.setupPayout();
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  
  goToList(): void {
    this.router.navigateByUrl('/products');
  }

  onPublish(id: string) {

    if (!this.isAdmin) {
      this.showSavingErrorMessage("Permission denied");
      return;
    }

    this.productService.changeStatus(id, ProductStatus.PUBLISH).subscribe(
     {
      next: () => {
        this.showSavingSuccessMessage();
        this.goToList();
      },
      error: (error) => {
        this.showSavingErrorMessage(error?.error?.message);
      }
     }
    )
  }

  onUnPublish(id: string) {
    this.productService.changeStatus(id, ProductStatus.UNPUBLISH).subscribe(
      {
       next: () => {
         this.showSavingSuccessMessage();
         this.goToList();
       },
       error: (error) => {
         this.showSavingErrorMessage(error?.error?.message);
       }
      }
     )
  }

  translateStatus(status: string): string {
    switch (status.toLowerCase()) {
      case 'published':
        return 'Pibliye';
      case 'unpublished':
        return 'Non pibliye';
      case 'awaiting_publication':
          return 'Ap tann piblikasyon';
      default:
        return status;
    }
  }
}
