import { Component, OnInit } from '@angular/core';
import { OrdersService } from '../orders.service';
import { Order, OrderStatus } from '../order.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { SellerPayoutComponent } from '../components/seller-payout/seller-payout.component';
import { ShippingActivityComponent } from "../components/shipping-activity/shipping-activity.component";
import { CustomerDetailsComponent } from "../components/customer-details/customer-details.component";
import { OrderItemDetailsComponent } from '../components/order-item-details/order-item-details.component';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslationService } from '../../shared/services/translation.service';
import { BaseComponent } from '../../shared/components/ui/base-component';

@Component({
  selector: 'app-order-details',
  standalone: true,
  imports: [
    CommonModule,
    NzSkeletonModule,
    NzIconModule,
    CommonModule,
    SellerPayoutComponent,
    ShippingActivityComponent,
    CustomerDetailsComponent,
    OrderItemDetailsComponent,
    NzPageHeaderModule,
    NzButtonModule
],
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.css'
})
export class OrderDetailsComponent extends BaseComponent implements OnInit {

  order!: Order;

  constructor(
    private readonly orderService: OrdersService,
    notificationService: NzNotificationService,
    router: Router,
    private readonly route: ActivatedRoute,
    translationService: TranslationService
  ) {
    super(router, notificationService, translationService);
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadOrder();
  }

  loadOrder() {
    const orderId = this.route.snapshot.params['id'];

    if (!orderId) {
      this.router.navigateByUrl(`/orders`);
      return;
    }

    this.orderService.findAll().subscribe(data => {
      const foundOrder = data.find(order => order.id === orderId);
      if (!foundOrder) {
        return;
      } 
      this.order = {
        ...foundOrder,
        seller_payout_data: {
          ...foundOrder.seller_payout_data,
          payout_data: {
            ...foundOrder.seller_payout_data.payout_data,
            currency: !foundOrder.seller_payout_data.payout_data.currency ? foundOrder.currency : foundOrder.seller_payout_data.payout_data.currency
          }
        }
      }

      console.log(this.order)
      this.loading = false;
    });
  }

  translateStatus(status: OrderStatus): string {
    const statuses = {
      [OrderStatus.AWAITING_PAYMENT]: this.t(
        'orders.statuses.awaiting_payment'
      ),
      [OrderStatus.PAID]: this.t('orders.statuses.paid'),
      [OrderStatus.PROCESSING]: this.t('orders.statuses.processing'),
      [OrderStatus.SHIPPED]: this.t('orders.statuses.shipped'),
      [OrderStatus.CANCELLED]: this.t('orders.statuses.cancelled'),
      [OrderStatus.DELIVERED]: this.t('orders.statuses.delivered'),
    };
    return statuses[status] || status;
  }

  onReject() {
    this.saving = true;
    this.orderService
        .changeStatus(this.order.id, { status: OrderStatus.CANCELLED})
        .subscribe({
          next: () => {
            this.saving = false;
            this.notificationService.success(
              'Success', 
              'Done yo anrejistre avek siksè!!!');
            this.onBack();
          },
          error: (error) => {
            this.saving = false;
            const message = 'Gen yon erè ki riv pandan wap anrejistre done yo!!!';
            this.notificationService.error(
                'Ouppssss', 
                message);
            }
        });
  }

  onBack(): void {
    this.router.navigateByUrl('/orders');
  }
}
