import { CommonModule } from "@angular/common";
import { BaseComponent } from "../ui/base-component";
import { Component } from "@angular/core";
import { NzResultModule } from 'ng-zorro-antd/result';

@Component({
    selector: 'app-forbidden',
    standalone: true,
    imports: [
      CommonModule,
      NzResultModule
    ],
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.css'],
})
export class ForbiddenComponent {
    
    constructor() { }

    ngOnInit(): void {}
}