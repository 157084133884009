<nz-content style="margin-top: 32px" *ngIf="userLoaded; else skeleton">

  <div nz-row class="welcome-section">
    <div nz-col [nzSpan]="24">
      <h2 *ngIf="user?.name" class="dashboard-welcome">
        {{ t('common.welcome') }}, {{ user?.name }}!
      </h2>
    </div>
  </div>

  <!-- Payout Setup Button -->
  <div *ngIf="!isSellerPaymentOnboardingCompleted && !isAdmin" class="center-align" style="margin-bottom: 32px;">
    <button nz-button nzType="primary" [nzLoading]="saving" (click)="setupPayout()" class="payout-btn">
      <i nz-icon nzType="dollar-circle" class="icon"></i> {{ t('common.payment_configuration_message')}} <i nz-icon nzType="link"></i>
    </button>
  </div>

  <!-- Dashboard Overview Section -->
  <div *ngIf="isSellerPaymentOnboardingCompleted" class="dashboard-overview">
    <nz-row [nzGutter]="16" style="margin-bottom: 32px;">
      <!-- Total Products Card -->
      <nz-col [nzSpan]="24" [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6">
        <nz-card class="statistic-card">
          <div class="card-content">
            <nz-statistic [nzValue]="totalProducts" nzTitle="Total Products"
                          [nzValueStyle]="{ fontSize: '36px', fontWeight: '600', color: '#4CAF50' }">
            </nz-statistic>
            <i nz-icon nzType="appstore" class="stat-icon"></i>
          </div>
        </nz-card>
      </nz-col>

      <!-- Total Orders Card -->
      <nz-col [nzSpan]="24" [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6">
        <nz-card class="statistic-card">
          <div class="card-content">
            <nz-statistic [nzValue]="totalOrders" nzTitle="Total Orders"
                          [nzValueStyle]="{ fontSize: '36px', fontWeight: '600', color: '#FF9800' }">
            </nz-statistic>
            <i nz-icon nzType="shopping-cart" class="stat-icon"></i>
          </div>
        </nz-card>
      </nz-col>

      <!-- Pending Orders Card -->
      <nz-col [nzSpan]="24" [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6">
        <nz-card class="statistic-card">
          <div class="card-content">
            <nz-statistic [nzValue]="pendingOrders" nzTitle="Pending Orders"
                          [nzValueStyle]="{ fontSize: '36px', fontWeight: '600', color: '#FF5722' }">
            </nz-statistic>
            <i nz-icon nzType="loading" class="stat-icon"></i>
          </div>
        </nz-card>
      </nz-col>

      <!-- Delivered Orders Card -->
      <nz-col [nzSpan]="24" [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="6">
        <nz-card class="statistic-card">
          <div class="card-content">
            <nz-statistic [nzValue]="deliveredOrders" nzTitle="Delivered Orders"
                          [nzValueStyle]="{ fontSize: '36px', fontWeight: '600', color: '#4CAF50' }">
            </nz-statistic>
            <i nz-icon nzType="check-circle" class="stat-icon"></i>
          </div>
        </nz-card>
      </nz-col>
    </nz-row>
  </div>
</nz-content>

<!-- Skeleton Loader -->
<ng-template #skeleton>
  <nz-skeleton [nzActive]="true" style="margin-bottom: 20px;"></nz-skeleton>
  <nz-skeleton [nzActive]="true" style="margin-bottom: 20px;"></nz-skeleton>
  <nz-skeleton [nzActive]="true" style="margin-bottom: 20px;"></nz-skeleton>
</ng-template>
