import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { CustomAuthService } from '../shared/components/auth/custom-auth.service';
import { PaymentsService } from '../payments/payments.service';
import { AuthUserInterface, UserRole } from '../shared/model/auth-user.interface';
import { Router } from '@angular/router';
import { TranslationService } from '../shared/services/translation.service';
import { BaseComponent } from '../shared/components/ui/base-component';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { ProductsService } from '../products/products.services';
import { OrdersService } from '../orders/orders.service';
import { combineLatest, from } from 'rxjs';
import { Order, OrderStatus } from '../orders/order.interface';
import { ApexAxisChartSeries, NgApexchartsModule } from 'ng-apexcharts'

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzProgressModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzPageHeaderModule,
    NzLayoutModule,
    NzTableModule,
    NzNotificationModule,
    NzSkeletonModule,
    NzFormModule,
    NzAlertModule,
    NzTabsModule,
    NzSelectModule,
    NzSwitchModule,
    NzUploadModule,
    NzImageModule,
    NzDividerModule,
    NzPopconfirmModule,
    NzDropDownModule,
    NzSpaceModule,
    NzCollapseModule,
    NzTagModule,
    NzCardModule,
    NzEmptyModule,
    NzListModule,
    NzStatisticModule,
    NgApexchartsModule
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent extends BaseComponent implements OnInit {

  user: AuthUserInterface | null = null;
  isAdmin: boolean = false;
  isSellerPaymentOnboardingCompleted: boolean = false;
  userLoaded: boolean = false;
  pageSize = 10;
  currentPage = 1;
  totalProducts!: number;
  totalOrders!: number;
  totalShipments!: number;
  deliveredOrders: number = 0;
  pendingOrders: number = 0;
  chartOptions: any;
  chartOptionsStatistic: any;
  orders: Order[] = [];
  @Input() title: string = "Order Statistics";
  @Input() series: ApexAxisChartSeries = [
    {
      name: "Orders",
      data: [120, 45, 75]
    }
  ];
  isDesktopView: boolean = window.innerWidth > 768;

  constructor(
    private readonly customAuthService: CustomAuthService,
    private readonly paymentService: PaymentsService,
    private productService: ProductsService,
    private readonly orderService: OrdersService,
    router: Router,
    notificationService: NzNotificationService,
    translationService: TranslationService
  ) {
    super(router, notificationService, translationService);
  }

  ngOnInit(): void {
    this.activateLoading();
    this.isDesktopView = window.innerWidth > 768;
    this.loadData();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isDesktopView = event.target.innerWidth > 768;
  }

  private loadData() {
    const sources = {
      user: this.customAuthService.authUser$,
      products: this.productService.findAll(this.currentPage, this.pageSize),
      orders: this.orderService.findAll()
    };
    this.data$ = combineLatest(sources)
      .subscribe({
        next: value => {
          this.user = value.user;
          this.isAdmin = value.user?.roles.includes(UserRole.ADMIN);
          this.isSellerPaymentOnboardingCompleted = !!value.user?.business_account?.is_onboarding_completed;
          this.totalProducts = value.products.total_items;
          if (!!value.orders) {
            this.totalOrders = value.orders.length;
            this.pendingOrders = value.orders.filter(order => ![OrderStatus.CANCELLED, OrderStatus.DELIVERED].includes(order.status))
                                  .length
            this.deliveredOrders = value.orders.filter(order => order.status === OrderStatus.DELIVERED)
                                  .length
            this.setChartOptions();
          }

          this.loadOrders();
          this.userLoaded = true; 
          this.deactivateLoading();
        }
      });
  }

  onNew(): void {
    if (this.isSellerPaymentOnboardingCompleted) {
      this.router.navigateByUrl('/products/create');
    } else {
      this.notificationService.warning(
        'Oupps', 
        this.t('products.new_product_warning'));
        this.setupPayout();
    }
  }

  public setChartOptions(): void {
    this.chartOptions = {
      series: [this.totalOrders, this.pendingOrders, this.deliveredOrders],
      chart: {
        type: "donut",
        height: 300
      },
      labels: [this.t('orders.total_orders'), this.t('orders.pending_orders'), this.t('orders.delivered')],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      colors: ["#008FFB", "#FEB019", "#00E396"],
      legend: {
        position: "bottom"
      }
    };

    this.chartOptionsStatistic = {
      series: this.series,
      chart: {
        type: "bar",
        height: 350
      },
      xaxis: {
        categories: [this.t('orders.total_orders'), this.t('orders.pending_orders'), this.t('orders.delivered')]
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: this.title
      },
      colors: ["#008FFB", "#FEB019", "#00E396"]
    };
  }

  translateStatus(status: OrderStatus): string {
    const statuses = {
      [OrderStatus.AWAITING_PAYMENT]: this.t(
        'orders.statuses.awaiting_payment'
      ),
      [OrderStatus.PAID]: this.t('orders.statuses.paid'),
      [OrderStatus.PROCESSING]: this.t('orders.statuses.processing'),
      [OrderStatus.SHIPPED]: this.t('orders.statuses.shipped'),
      [OrderStatus.CANCELLED]: this.t('orders.statuses.cancelled'),
      [OrderStatus.DELIVERED]: this.t('orders.statuses.delivered'),
    };
    return statuses[status] || status;
  }

  private loadOrders() {
    this.orderService
      .findAll()
      .subscribe((data) => {
        this.orders = data.slice(0, 10);
        this.deactivateLoading();
    });
  }

  goProducts(): void {
    this.router.navigateByUrl('/products');
  }

  goOrders(): void {
    this.router.navigateByUrl('/orders');
  }

  setupPayout() {
    this.activateSaving();
    this.paymentService.setupPayout().subscribe((data: any) => {
      window.location.href = data?.url;
      this.deactivateSaving();
    });
  }
}