<ng-container>
  <nz-page-header [nzTitle]="title" nzBackIcon (nzBack)="onBack()">
    <ng-template #title>
      {{ isOnCreationMode ? 'Kreye livrezon' : 'Modifye livrezon' }}
      <span *ngIf="form && form.dirty">*</span>
    </ng-template>
    <nz-page-header-extra>
      <ng-container>
        <button nz-button nzType="primary" (click)="onSave()" [nzLoading]="saving">
          Anrejistre
        </button>
      </ng-container>
    </nz-page-header-extra>
  </nz-page-header>
</ng-container>

<div nz-col [nzSpan]="24" style="margin-top: 24px;">
  <nz-divider nzText="Livrezon" nzOrientation="left"></nz-divider>
  <ng-container>
    <form nz-form nzLayout="vertical" [formGroup]="form">
      <ng-container>
        <div nz-col [nzSpan]="24">
          <div nz-row>
            <div nz-col [nzSpan]="12" class="padding-col">
              <nz-form-item>
                <nz-form-label nzRequired>
                  Type
                  <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Chwazi kalite livrezon an eksplike detaye." style="margin-left: 8px;"></i>
                </nz-form-label>
                <nz-form-control nzErrorTip="Description is required">
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a type" formControlName="type">
                    <nz-option *ngFor="let type of types" [nzValue]="type.value" [nzLabel]="type.label"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12" class="padding-col">
              <nz-form-item>
                <nz-form-label nzRequired>
                  Deskripsyon
                  <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Ajoute yon deskripsyon pou livrezon an." style="margin-left: 8px;"></i>
                </nz-form-label>
                <nz-form-control nzErrorTip="Description is required">
                  <input nz-input formControlName="description" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div nz-col [nzSpan]="24">
          <div nz-row>
            <div nz-col [nzSpan]="12" class="padding-col">
              <nz-form-item>
                <nz-form-label nzRequired>
                  Pri ({{ getCurrencySymbol(shipment.currency) }})
                  <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Pri total livrezon an." style="margin-left: 8px;"></i>
                </nz-form-label>
                <nz-form-control nzErrorTip="Pri pwodui an obligatwa">
                  <input nz-input type="number" formControlName="price" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12" class="padding-col">
              <nz-form-item>
                <nz-form-label>
                  Diskont ({{ getCurrencySymbol(shipment.currency) }})
                  <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Valè diskont sou livrezon an." style="margin-left: 8px;"></i>
                </nz-form-label>
                <nz-form-control nzErrorTip="Pri pwodui an obligatwa">
                  <input nz-input type="number" formControlName="discount_amount" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div nz-col [nzSpan]="24">
          <div nz-row>
            <div nz-col [nzSpan]="6" class="padding-col">
              <nz-form-item>
                <nz-form-label nzRequired>
                  Limit Minimum
                  <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Chwazi limit minimòm pou livrezon." style="margin-left: 8px;"></i>
                </nz-form-label>
                <nz-form-control nzErrorTip="Pri pwodui an obligatwa">
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select deadline" formControlName="minLimit">
                    <nz-option *ngFor="let minLimit of minLimits" [nzValue]="minLimit" [nzLabel]="minLimit"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6" class="padding-col">
              <nz-form-item>
                <nz-form-label>
                  Frekans Min
                  <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Frekans minimòm pou livrezon yo." style="margin-left: 8px;"></i>
                </nz-form-label>
                <nz-form-control nzErrorTip="Pri pwodui an obligatwa">
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select deadline" formControlName="minFrequency">
                    <nz-option *ngFor="let frequency of frequencies" [nzValue]="frequency.value" [nzLabel]="frequency.label"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6" class="padding-col">
              <nz-form-item>
                <nz-form-label>
                  Limit Max
                  <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Limit maksimòm pou livrezon yo." style="margin-left: 8px;"></i>
                </nz-form-label>
                <nz-form-control nzErrorTip="select">
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select deadline" formControlName="maxLimit">
                    <nz-option *ngFor="let maxLimit of maxLimits" [nzValue]="maxLimit" [nzLabel]="maxLimit"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6" class="padding-col">
              <nz-form-item>
                <nz-form-label nzRequired>
                  Frekans Maximum
                  <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Frekans maksimòm pou livrezon yo." style="margin-left: 8px;"></i>
                </nz-form-label>
                <nz-form-control nzErrorTip="Pri pwodui an obligatwa">
                  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select deadline" formControlName="maxFrequency">
                    <nz-option *ngFor="let frequency of frequencies" [nzValue]="frequency.value" [nzLabel]="frequency.label"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <ng-container>
          <span>
            {{ getFormattedDeadline() }}
          </span>
        </ng-container>

      </ng-container>

      <nz-divider nzText="Zòn" nzOrientation="left"></nz-divider>
      <div nz-row>
        <div nz-col [nzSpan]="12" class="padding-col">
          <nz-form-item>
            <nz-form-label nzRequired>
              Peyi
              <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Seleksyone peyi kote livrezon fèt." style="margin-left: 8px;"></i>
            </nz-form-label>
            <nz-form-control nzErrorTip="Seleksyone peyi">
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a type" formControlName="country">
                <nz-option *ngFor="let country of countries" [nzValue]="country.code" [nzLabel]="country.name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" class="padding-col">
          <nz-form-item>
            <nz-form-label>
              Eta/Depatman/Pwovens
              <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Eta/Depatman/Pwovens kote livrezon fèt." style="margin-left: 8px;"></i>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="stateOrDepartment" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      
      <div nz-row>
        <div nz-col [nzSpan]="12" class="padding-col">
          <nz-form-item>
            <nz-form-label>
              Vil
              <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Vil kote livrezon fèt." style="margin-left: 8px;"></i>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="city" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row>
        <div nz-col [nzSpan]="12" class="padding-col">
          <nz-form-item>
            <nz-form-label>Aktif
              <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Endike si livrezon sa aktif" style="margin-left: 8px;"></i>
            </nz-form-label>
            <nz-form-control>
              <nz-switch formControlName="is_active"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" class="padding-col">
          <nz-form-item>
            <nz-form-label>Disponib pou tout pwodui
              <i nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip="Disponib pou tout pwodui yo" style="margin-left: 8px;"></i>
            </nz-form-label>
            <nz-form-control>
              <nz-switch formControlName="is_available_for_all_products"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </ng-container>
</div>
