import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTableModule, NzTableQueryParams } from 'ng-zorro-antd/table';
import { BaseComponent } from '../../shared/components/ui/base-component';
import { ShipmentsService } from '../shipments.service';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslationService } from '../../shared/services/translation.service';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { Shipment } from '../../shared/model/shipment';
import { AuthUserInterface, UserRole } from '../../shared/model/auth-user.interface';
import { CustomAuthService } from '../../shared/components/auth/custom-auth.service';
import { PaymentsService } from '../../payments/payments.service';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { DeadlineFrequency } from '../../shared/model/deadLineFrequency';
import { ShipmentEnum } from '../../shared/model/shipment-enum';

@Component({
  selector: 'app-shipments',
  standalone: true,
  imports: [
    NzPageHeaderModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzPaginationModule,
    NzIconModule,
    NzGridModule,
    NzButtonModule,
    NzDropDownModule,
    NzSkeletonModule,
    NzCardModule,
    NzFlexModule,
    NzDividerModule
  ],
  templateUrl: './shipments.component.html',
  styleUrl: './shipments.component.css'
})
export class ShipmentsComponent extends BaseComponent implements OnInit, OnDestroy {

  isSearchVisible = false;
  shipments: any;
  pageSize = 10;
  currentPage = 1;
  totalItems = 0;
  totalPages!: number;
  isSellerPaymentOnboardingCompleted: boolean = false;
  authUser: AuthUserInterface | undefined;
  isAdmin: boolean = false;
  isDesktopView: boolean = window.innerWidth > 768;
  isVertical: boolean = true;
  translationMap: { [key in ShipmentEnum]: string } = {
    [ShipmentEnum.FIXED_SHIPPING]: 'Livrezon Fiks',
    [ShipmentEnum.PER_UNIT_SHIPPING]: 'Livrezon Pa Inite'
  };

  getShipmentTypeTranslation(type: ShipmentEnum): string {
    return this.translationMap[type] || type;
  }

  constructor(
    private shipmentsService: ShipmentsService,
    private readonly authService: CustomAuthService,
    private readonly paymentService: PaymentsService,
    router: Router,
    notificationService: NzNotificationService,
    translationService: TranslationService
  ) {
    super(router, notificationService, translationService);
  }

  public ngOnInit(): void {
    this.activateLoading();
    this.loadShipments();
    this.setUser();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isDesktopView = event.target.innerWidth > 768;
  } 

  private loadShipments() {
    this.shipmentsService
      .findAll()
      .subscribe(data => {
        this.shipments = data;
        this.deactivateLoading();
      });
  }

  setUser() {
    this.authService.authUser$.subscribe((user: AuthUserInterface) => {
      this.authUser = user;
      this.isAdmin = user?.roles.includes(UserRole.ADMIN);
      this.isSellerPaymentOnboardingCompleted = !!user?.business_account?.is_onboarding_completed;
    });
  }

  setupPayout() {
    this.paymentService.setupPayout().subscribe((data: any) => {
      window.location.href = data?.url;
    });
  }

  onNew(): void {
    if (this.isSellerPaymentOnboardingCompleted) {
      this.router.navigateByUrl('/shipments/create');
    } else {
      this.notificationService.warning(
        'Oupps', 
        this.t('products.new_product_warning'));
        this.setupPayout();
    }
  }

  toggleSearch(): void {

  }

  onPageChange(pageIndex: number): void {
    this.currentPage = pageIndex;
    this.loadShipments();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.loadShipments();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // Implement query params change handling if needed
  }

  onEdit(id: string): void {
    this.router.navigateByUrl(`/shipments/${id}/edit`);
  }

  getFormattedCoverage(shipment: Shipment) {
    const areaParts = [
      shipment.coverage_area.neighborhood,
      shipment.coverage_area.city,
      shipment.coverage_area.stateOrDepartment,
      shipment.coverage_area.country.name
    ];
  
    return areaParts.filter(Boolean).join(' - ');
  }

  getFormattedDeadline(shipment: Shipment): string {
    const translations: { [key in DeadlineFrequency]: string } = {
      [DeadlineFrequency.HOURS]: 'H',
      [DeadlineFrequency.DAYS]: 'Jou',
      [DeadlineFrequency.WEEK]: 'Semèn',
      [DeadlineFrequency.MONTH]: 'Mwa'
    };

    return `Antre ${shipment.deadline.minLimit} ${translations[shipment.deadline.minFrequency]} a ${shipment.deadline.maxLimit} ${translations[shipment.deadline.maxFrequency]}`;
  }
}
