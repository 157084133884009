import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { CustomLanguage } from './shared/internationalization/customLanguage';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { CustomAuthService } from './shared/components/auth/custom-auth.service';
import { environment } from '../environments/environment';
import { NzSpinModule } from 'ng-zorro-antd/spin';

registerLocaleData(en, 'custom');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, NzSpinModule],
  providers: [
    { provide: NZ_I18N, useValue: CustomLanguage.customLanguage },
    { provide: LOCALE_ID, useValue: 'custom' },
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isLoading = true;

  constructor(
    private readonly router: Router,
    private authService: CustomAuthService) {}

  ngOnInit() {
    this.authService
      .validateToken()
      .then((tokenValidated) => {
        if (!tokenValidated) {
          this.authService.login();
        } else {
          this.authService.getUserFromApi().subscribe({
            next: (authUser) => {
              if (!this.authService.userAuthorized()) {
                window.location.href = environment.SELLER_ENDPOINT;
              }
            },
            error: (err) => {
              console.error('Erro ao carregar os dados do cliente:', err);
              window.location.href = environment.SELLER_ENDPOINT;
              return;
            },
          });
        }
      })
      .catch((error) => {
        console.error('Erro na validação do token:', error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
