import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Order, OrderStatus } from '../../order.interface';
import { BaseComponent } from '../../../shared/components/ui/base-component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { TranslationService } from '../../../shared/services/translation.service';

@Component({
  selector: 'app-shipping-activity',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './shipping-activity.component.html',
  styleUrl: './shipping-activity.component.css'
})
export class ShippingActivityComponent extends BaseComponent implements OnInit {

  @Input() order!: Order;

  allStatuses: OrderStatus[] = [];

  constructor(
    notificationService: NzNotificationService,
    router: Router,
    translationService: TranslationService
  ) {
    super(router, notificationService, translationService);
  }

  ngOnInit() {
    this.allStatuses = this.order.order_item_status_logs.map(log => log.order_item_status)
    console.log(this.order)
  }

  getStatusLabel(status: OrderStatus): string {
    const statusLog = this.order.order_item_status_logs.find(log => log.order_item_status === status);
    return statusLog ? `${this.getShippingActivityDescription(status)} - ${new Date(statusLog.occurred_on).toLocaleString()}` : status;
  }

  isStatusActive(status: string): boolean {
    return this.order.order_item_status_logs.some(log => log.order_item_status === status);
  }

  getShippingActivityDescription(status: OrderStatus): string {
    const activities = {
      [OrderStatus.AWAITING_PAYMENT]: this.t('orders.activities.order_created'),
      [OrderStatus.PAID]: this.t('orders.activities.payment_confirmed'),
      [OrderStatus.PROCESSING]: this.t('orders.activities.order_processing'),
      [OrderStatus.SHIPPED]: this.t('orders.activities.order_shipped'),
      [OrderStatus.CANCELLED]: this.t('orders.activities.order_cancelled'),
      [OrderStatus.DELIVERED]: this.t('orders.activities.order_delivered'),
    }
    return activities[status] || status
  }
}
