import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, retry, switchMap } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { CustomAuthService } from '../shared/components/auth/custom-auth.service';
import { Product } from '../shared/model/product';
import { Category } from '../shared/model/category';
import { Page } from '../shared/model/pagination';
import { ProductStatus } from './product.types';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {

  private readonly API = `${env.API_URL}/management/products`;


  constructor(
    private readonly http: HttpClient,
    private readonly customAuthService: CustomAuthService
  ) {}

  findAll(current_page: number, total_pages: number): Observable<Page<Product>> {
    const queryString = `?current_page=${current_page}&total_pages=${total_pages}`;
    return this.http.get<Page<Product>>(`${this.API}${queryString}`)
  }


  findById(id: string): Observable<Product> {
    return this.http.get<Product>(`${this.API}/${id}`)
  }

  findCategories(): Observable<Category[]> {
    const url = `${env.API_URL}/categories`;
    return this.http.get<Category[]>(`${url}`)
  }

  create(formData: FormData): Observable<Product> {
    return this.http.post<Product>(this.API, formData);
  }

  removeImage(id: string, image_id: string) {
    const url = `${this.API}/${id}/images`;
    return this.http.patch<any>(url, {image_id});
  }  

  update(formData: FormData, productId: string): Observable<Product> {
    const url = `${this.API}/${productId}`;
    return this.http.put<Product>(url, formData);
  }

  upload(images: any, id: string): Observable<any> {
    const url = `${env.API_URL}/${id}/images-upload`;
    return this.http.patch<any>(url, images)
  }

  changeStatus(id: string, status: ProductStatus) {
    return this.http.patch<any>(`${this.API}/${id}/change-status?status=${status}`,{})
  }
}
