<div class="customer">
    <header>
        <strong>{{ t('orders.customer_information')}}</strong>
    </header>

    <div class="customer-info">
        <div class="customer-name">
            <h3>{{ order.customer.name }}</h3> 
        </div>
        <hr class="divider">

        <div class="customer-shipping-contact">
            <h3>{{ t('orders.shipping_contact')}}</h3>
            <p>{{ order.customer.shipping_contact.name }}</p>
            <p><a href="tel:{{ order.customer.shipping_contact.phoneNumber }}">{{ order.customer.shipping_contact.phoneNumber }}</a></p>
        </div>
        <hr class="divider">

        <div class="customer-shipping-address">
            <h3>{{ t('orders.shipping_address')}}</h3>
            <p>{{ order.customer.shipping_address.number }}, {{ order.customer.shipping_address.street }}</p>
            <p>{{ order.customer.shipping_address.zipCode }} - {{ order.customer.shipping_address.neighborhood }}</p>
            <p>{{ order.customer.shipping_address.state_or_department }} - {{ order.customer.shipping_address.country.name }}</p>
        </div>
        <hr class="divider">

        <div class="customer-shipping-option">
            <h3>{{ t('orders.shipping_option_chosen')}}</h3>
            <p>{{ order.shipment_description }}</p>
            <p>{{ t('orders.shipping_total_price')}}:  {{ order.parent.shipping_cost | currency: order.currency }}</p>
        </div>
    </div>
</div>