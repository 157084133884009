<div class="seller-payout-data">
    <header>
        <strong>{{ t('orders.seller_payout_details')}}</strong>
    </header>
    <div class="payout-info">
        <p>{{ t('orders.seller_name')}}: <strong>{{ order.seller_payout_data.business_name }}</strong></p>
        <p>{{ t('orders.payout_amount')}}: <span style="font-weight: bold;">{{ order.seller_payout_data.payout_data.amount | currency: order.seller_payout_data.payout_data.currency }}</span></p>
        <p>{{ t('orders.payout_status')}}: <span style="font-weight: bold;">{{ order.seller_payout_data.payout_data.status }}</span></p>
        <p>{{ t('orders.payout_date')}}: <span style="font-weight: bold;">{{ order.seller_payout_data.payout_data.payout_date | date: 'dd/MM/yyyy HH:mm:ss'}}</span></p>
        <p>{{ t('common.platform_percent_fee')}}: <span style="font-weight: bold;">{{ order.seller_payout_data.platform_fee_percent }}</span></p>
    </div>
</div>
