import { FormGroup } from "@angular/forms";

export function showValidationErrors(form: FormGroup): void {
    if (form.valid) {
      return;
    }
  
    Object.values(form.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
}