<ng-container *ngIf="!loading; else skeleton">
  <nz-page-header nzTitle="{{ t('orders.details')}}" nzBackIcon (nzBack)="onBack()">
    <nz-page-header-extra>
      <button nz-button nzType="primary" *ngIf="this.order.capabilities['is_cancellable']" nzDanger="" (click)="onReject()" [nzLoading]="saving">
        {{ t('orders.cancel')}}
      </button>
    </nz-page-header-extra>
  </nz-page-header>
  <div class="order-details-container">
    <div class="header">
      <div class="title-and-details">
        <div class="details">
          <p>{{ t('orders.order_id') }}: <span style="font-weight: bold;">{{order.public_id}}</span></p>
          <p>Status: 
            <span>
              {{ translateStatus(order.status) }}
            </span>
          </p>
          <p>{{ t('orders.created_at')}}: <strong>{{ order.created_at |  date: 'dd/MM/yyyy HH:mm:ss'  }}</strong></p>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="content-wrapper">
        <app-order-item-details [order]="order"></app-order-item-details>
        <app-customer-details [order]="order"></app-customer-details>
      </div>
      <app-shipping-activity [order]="order"></app-shipping-activity>
      <app-seller-payout [order]="order"></app-seller-payout>
    </div>
  </div>
</ng-container>
<ng-template #skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>