import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { showValidationErrors } from "../../Errors/form-errors";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslationService } from "../../services/translation.service";


@Component({
    template: '',
})
export abstract class BaseComponent implements OnDestroy {

    protected commonTranslations: any = null;
    protected data$: Subscription = new Subscription();
    public loading: boolean = false;
    public saving: boolean = false;
    public form!: FormGroup;
    public formErrors: string[] = [];

    constructor(
        protected readonly router: Router,
        protected readonly notificationService: NzNotificationService,
        private translationService?: TranslationService
    ) { }
        
    protected activateLoading(): void {
        this.loading = true;
    }

    protected deactivateLoading(): void {
        this.loading = false;
    }

    protected activateSaving(): void {
        this.saving = true;
    }

    protected deactivateSaving(): void {
        this.saving = false;
    }

    protected clearFormErrors(): void {
        this.formErrors = [];
    }

    protected showFormValidations(): void {
        showValidationErrors(this.form);
    }

    protected showSavingSuccessMessage(): void {
        this.notificationService.success(
            'Success', 
            'Done yo anrejistre avek siksè!!!');
    }

    protected showSavingErrorMessage(message?: string): void {
        if (!message) {
            message = 'Gen yon erè ki riv pandan wap anrejistre done yo!!!';
        }
        this.notificationService.error(
            'Ouppssss', 
            message);
    }

    protected showLoadingErrorMessage(): void {
        this.notificationService.error(
            'Ouppps', 
            'Gen yon erè nan telechajman pwodui yo!!!');
    }

    protected t(key: string): string {
        if (this.translationService) {
            return this.translationService.translate(key);
        }
        return key;
    }

    ngOnDestroy(): void {
        if (this.data$ && !this.data$.closed) {
            this.data$.unsubscribe();
        }
    }

}
