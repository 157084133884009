<ng-container *ngIf="!loading; else skeleton">
  <div class="main-container">
    <nz-page-header
      nzTitle="{{
        isOnCreationMode
          ? t('categories.create_category')
          : t('categories.update_category')
      }}"
      nzBackIcon
      (nzBack)="onBack()"
    >
      <nz-page-header-extra>
        <ng-container *ngIf="selectedTab === 0 || selectedTab === 1">
          <button
            nz-button
            nzType="primary"
            (click)="onSave()"
            [nzLoading]="saving"
          >
            {{ t("common.save") }}
          </button>
        </ng-container>
      </nz-page-header-extra>
    </nz-page-header>

    <div nz-col [nzSpan]="24">
      <form nz-form nzLayout="vertical" [formGroup]="form">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label nzRequired>{{ t("common.name") }}</nz-form-label>
              <nz-form-control nzErrorTip="Non kategori a obligatwa">
                <input nz-input formControlName="name" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label nzRequired>{{
                t("common.description")
              }}</nz-form-label>
              <nz-form-control nzErrorTip="Deskripsyon kategori a obligatwa">
                <textarea nz-input formControlName="description"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label>{{ t("common.tags") }}</nz-form-label>
              <nz-form-control>
                <nz-select formControlName="tags" nzMode="tags"></nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label>{{ t("common.image") }}</nz-form-label>
              <nz-form-control>
                <app-file-uploader
                  (fileUploaded)="onFileUploaded($event)"
                ></app-file-uploader>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
